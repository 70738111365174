import * as React from 'react';
import { Grid } from '@material-ui/core';
import Draggable from 'react-draggable';
import años1 from './img/años1.png';
import { Dialog, DialogTitle, DialogContent, DialogActions, Paper, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';






function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper style={{ borderRadius: 13 }} {...props} />
        </Draggable>
    );

}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal() {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                TransitionComponent={Transition}

            >
                <Grid container={2}>

                    <div style={{ backgroundImage: 'url("' + años1 + '")', backgroundSize: "cover", width: 500, height: 400 }}>

                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            <Grid container={2}>
                                <Grid item xs={11}>

                                </Grid>
                                <Grid item xs={1}>

                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <Close onClick={handleClose} style= {{marginTop: -15, marginLeft: 10 }} />
                                    </IconButton>
                                </Grid>
                            </Grid>

                        </DialogTitle>
                        <DialogContent>

                        </DialogContent>
                        <DialogActions>
                            {/* <Button autoFocus onClick={handleClose}>
              Cancel
              </Button>
            <Button onClick={handleClose}>Subscribe</Button> */}
                        </DialogActions>

                    </div>
                </Grid>
            </Dialog>
        </div>
    );
}
