import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Zoom } from 'react-slideshow-image';

/* Assets */
import hologramaBase from './assets/img/reflector.png'
import hologramaContainer from './assets/img/hologramaContainer.png'

import fabricaSof from './assets/img/1.svg'
import infraestructura from './assets/img/2.svg'
import administracion from './assets/img/3.svg'
import posicionamiento from './assets/img/4.svg'
import RPA from './assets/img/5.svg'
import experiencia from './assets/img/6.svg'
import consultoriaTI from './assets/img/7.svg'


import uno from './assets/img/fabrica.png'
import dos from './assets/img/Comercializacion.png'
import tres from './assets/img/Administración.png'
import cuatro from './assets/img/Diseño.png'

import seis from './assets/img/Experiencia.png'
import siete from './assets/img/TI.png'

import Modal from '../../Modal';
import { styles } from './constants';

import './index.css'



/*Animatins*/
import Fade from 'react-reveal/Fade';


var items = [
    { id: 1, title: 'item #1', src: fabricaSof, box: uno },
    { id: 2, title: 'item #2', src: infraestructura, box: dos },
    { id: 3, title: 'item #3', src: administracion, box: tres },
    { id: 4, title: 'item #4', src: posicionamiento, box: cuatro },
    { id: 5, title: 'item #5', src: RPA, box: siete },
    { id: 6, title: 'item #6', src: experiencia, box: seis },
    { id: 7, title: 'item #7', src: consultoriaTI, box: siete }
]

var properties = JSON.parse(localStorage.getItem("properties"))

class services extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            show: false,
            left: 0,
            right: 0
        })
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var y = window.scrollY;
        // alert(y)

        if (y > 2800 && y < 3500) {
            this.setState({
                left: this.state.left + 25,
                right: this.state.right - 25
            })
        } else {
            this.setState({
                left: 0,
                right: 0
            })
        }

    }


    render() {

        const { classes } = this.props;



        const zoomOutProperties = {
            duration: 5000,
            transitionDuration: 500,
            infinite: true,
            indicators: false,
            scale: 0.4,
            arrows: false
        }
        return (
            <Grid container className={classes.serviceContainer}>
                {properties.endpoints.modal === 1 && 
                 <Modal/>
                }
                <Grid item xs={12}>
                    <Fade bottom>
                        <Typography className={classes.textScrollR} style={{ marginTop: 100, transform: "translateX(" + this.state.left + "px)" }}>ENFOCADOS</Typography>
                        <Typography className={classes.textScroll} style={{ marginTop: 80, marginBottom: 50, textAlign: "right", transform: "translateX(" + this.state.right + "px)" }}>EN TUS METAS</Typography>
                        {/* <Typography className={classes.textScrollR} style={{ marginTop: 100, }}>ENFOCADOS</Typography>
                        <Typography className={classes.textScroll} style={{ marginTop: 80, marginBottom: 50, textAlign: "right", }}>EN TUS METAS</Typography> */}
                    </Fade>
                </Grid>

                <Grid xs={12} >
                    <Fade bottom>
                        <Typography style={{ color: "white", fontSize: 25, textAlign: "center" }}>Deposita tu confianza y el crecimiento de tu empresa en nuestra</Typography>
                        <Typography style={{ color: "#61dafb", fontSize: 25, textAlign: "center" }}>Experiencia tecnológica</Typography>
                    </Fade>
                </Grid>

                <Grid xs={12} >

                    {/* Carrousel */}
                    <Fade top>
                        <Grid container>
                            <Grid item xs={12} md={4}></Grid>

                            <Grid item xs={12} md={4}>

                                <Grid container>
                                    <Grid item xs={12} style={{ height: "25vh" }}>
                                        <img className="rotate" src={hologramaContainer} alt="Holograma" style={{ width: "100%", objectFit: "cover", margin: "auto", display: "block", borderRadius: "50%", padding: "100px", background: "radial-gradient(rgb(63 81 181 / 37%), rgb(38 48 108 / 9%), rgb(7 14 89 / 61%))" }} ></img>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className="slide-container" style={{ margin: "auto" }} >
                                            <Zoom {...zoomOutProperties} >
                                                {items.map(item => (
                                                    <div className="each-slide" style={{
                                                        margin: "auto",
                                                    }} >

                                                        <img src={item.src} alt="items" className={classes.serviceImg} ></img>
                                                    </div>
                                                ))}
                                            </Zoom>
                                        </div>
                                    </Grid>


                                    <Grid item xs={12} style={{ marginTop: 40, }}>
                                        <img src={hologramaBase} alt="laptop" style={{ width: "100%", objectFit: "cover", margin: "auto", display: "block", }}></img>
                                    </Grid>


                                </Grid>



                            </Grid>

                            <Grid item xs={12} md={4}>

                                <Grid item xs={12}>

                                    <div className={classes.containerBox}>
                                        <div className="slide-container" >
                                            <div className={classes.marginBox}>
                                                <Zoom className="floatContainerRight"  {...zoomOutProperties}  >
                                                    {items.map(item => (
                                                        <div className="each-slide">
                                                            <img src={item.box} alt="laptop" style={{ height: 150, width: 350, }} />
                                                        </div>
                                                    ))}
                                                </Zoom>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Fade>

                </Grid>


            </Grid >
        );
    }
}
const Services = withStyles(styles, { withTheme: true })(services);
export default Services;