import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

/* Components */
import Header from '../Header';
import Cover from './Cover'
import InnovationFriends from './InnovationFriends';
import AboutUs from './AboutUs';
import Services from './Services';
import Customers from './Customers';
import Projects from './Projects';
import Footer from '../Footer';
// import Partners from './Partners';
// import Partner from './Partners/PartnerData';



class Home extends Component {
    render() {
        return (
            <Grid container>
                <Header logo={'#33aadb'} />
                <Cover />
                {/* <Partners /> */}
                {/* <Partner /> */}
                <InnovationFriends />
                <AboutUs />
                <Services />
                <Customers />
                <Projects />
                <Footer />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}

export default connect(
    mapStateToProps,
)(Home);