import React, { Component } from 'react';
import { Grid, List, Grow, ListItem, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { styles } from './constants';
import { connect } from "react-redux";

import { openContact } from '../../../Redux/Actions/_actionContacto';

import './index.css';

const floatMenu = [
    {
        name: "Inicio",
        url: "/"
    },
    {
        name: "Nosotros",
        url: "/Nosotros"
    },
    {
        name: "Servicios",
        url: "/Servicios"
    }
]

class rightMenu extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            show: false,
            color: "white"
        })
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var y = window.scrollY;
        // alert(y)
        if (y > 25) {
            this.setState({
                show: true
            })
        } else {
            this.setState({
                show: false
            })
        }

        var color = "";
        if (y > 500 && y < 3000) {
            color = "black";
        } else if (y > 4000 && y < 5500) {
            color = "black";
        } else {
            color = "white";
        }

        this.setState(
            {
                color: color
            }
        )

    };

    handleContact = () => {
        this.props.openContact(true);
    };

    handleToTop = () => {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;
        return (

            <>
                <Grid style={{ zIndex:999999999999999999999}}>
                    <Grow in={this.state.show} >
                        <List id="floatMenu" className={classes.rightMenu}>
                            {floatMenu.map(menu => (
                                <ListItem onClick={this.handleToTop}  button alignItems="center" style={{ borderRadius: 50 }} >
                                    <Link to={menu.url} style={{ textDecoration: 'none', color: "white" }}>{menu.name} </Link>
                                </ListItem>
                            ))}
                            <ListItem onClick={this.handleContact} button alignItems="center" style={{ borderRadius: 50, color: "white" }}>
                                Contacto
                             </ListItem>
                        </List>
                    </Grow>
                </Grid>
                {/* <Grid style={{ marginTop: "80vh", }}>
                    <IconButton onClick={this.handleLightDark} className={classes.floatButtom}>
                        <i class="icon-tema" style={{ color: "white" }} />
                    </IconButton>
                </Grid>
                <Grid style={{ marginTop: "90vh", }}>
                    <IconButton className={classes.floatButtom}>
                        <i class="icon-chat" style={{ color: "white" }} />
                    </IconButton>
                </Grid> */}
            </>


        );
    }
}

const RightMenu = withStyles(styles, { withTheme: true })(rightMenu);



function mapStateToProps(state) {
    return {
        contactPayload: state.contactReducer.contactPayload,

    };
}

export default connect(mapStateToProps, {
    openContact,

})(RightMenu);
