import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import icono from './assets/img/icono3.png';
import Design from './assets/img/Design.png';
import { stylesServices } from '../constants.js';

/*Animations*/
import Fade from 'react-reveal/Fade';

export class graphicDesign extends Component {
    render() {
        const { classes } = this.props
        return (
            <Grid container id='seo' item xs={12} >
                <Grid container >
                    <Grid xs={12} sm={6} md={6} container alignItems='center'>
                        <Grid container className={classes.padding}>
                            <Grid xs={12} sm={12} md={12}
                                container
                                justify='center' style={{ marginTop: '5vh' }}>
                                <Fade bottom cascade>
                                    <img src={icono} alt='img' width='150' height= "150" className={classes.iconoTecno} />
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={2} md={2} />
                            <Grid xs={12} sm={10} md={8} >
                                <Fade left cascade>
                                    <Typography className={classes.TypographyTitle1} style={{ color: '#4D4D4D' }}>Diseño Gráfico Y </Typography>
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={2} md={2} />
                            <Grid xs={12} sm={1} md={1} />
                            <Grid xs={12} sm={10} md={10} >
                                <Fade left cascade>
                                    <Typography className={classes.TypographyTitle2} style={{ color: '#4D4D4D' }}>Posicionamiento Web</Typography>
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={1} md={1} />
                            <Grid xs={12} sm={1} md={1} />
                            <Grid xs={12} sm={10} md={10} container justify='flex-start' alignItems='center' className={classes.texto} >
                                <Fade left cascade>
                                    <Typography className={classes.text} style={{ color: '#5D677F' }}>Creamos tu marca de principio a fin y mediante una serie de optimizaciones, con técnicas SEO, hacemos que cada cliente logre posicionar su sitio web en los primeros resultados de los buscadores.</Typography>
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={1} md={1} />
                        </Grid>

                    </Grid>

                    <Grid xs={12} sm={6} md={6} >
                        <Fade right cascade>
                            <img src={Design} alt='img' className={classes.imgTecno} />
                        </Fade>
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}

const GraphicDesign = withStyles(stylesServices, { withTheme: true })(graphicDesign);
export default GraphicDesign
