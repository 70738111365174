import Fondoformulario from "./assets/img/Fondoformulario.jpg";

export const styles = (theme) => ({
  googleMap: {
    width: "110%",
    height: "50vh",
    border: 0,
    marginLeft: "-4vh",
    [theme.breakpoints.down("sm")]: {
      height: "50vh",
      width: "122%",
    },
  },
  root: {
    width: "100%",
    marginLeft: "-4vh",
    borderBottom: "1px solid white",
  },
  contenedor: {
    width: 300,
    background: "#dfdee3",
    borderRadius: "0px",
    paddingTop: "10px",
    height: 366,
    marginLeft: -25,
    marginTop: -20,
    [theme.breakpoints.down("sm")]: {
      height: "50vh",
      width: "122%",
    },
  },
  logo: {
    width: 80,
    marginLeft: "5vh",
    marginTop: "-5vh",

    [theme.breakpoints.down("sm")]: {
      marginTop: "-10vh",
    },
  },
  iconos: {
    textAlign: "left",
    marginLeft: 20,
    marginTop: "5vh",
  },
  contenedor2: {
    width: 310,
    backgroundImage: `url(${Fondoformulario})`,
    borderRadius: "0px",
    height: 367,
    marginTop: -20,
    position: "static",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2px",
      marginLeft: "-4vh",
      height: "50vh",
      width: "122%",
    },
    
  },

  textColor: {
    input: { color: "white" },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
        color: "white",
      },
    },
    color: "white",
  },

  button: {
    marginTop: 10,
    marginRight: 110,
    width: "80px",
    padding: 5,
    color: "white",
    borderRadius: "70px",
    border: "1px solid #006eff",
    fontSize: 9,
    background: "#1b1464",
  },
  close1: {
    color: "black",
    marginTop: 5,
    marginRight: 30,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  close2: {
    color: "white",
    marginTop: 5,
    marginRight: 30,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
});
