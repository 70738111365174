import { ERROR_CONTROL} from '../constants';

export const errorControl = ( payload, message, alertColor) =>{
    return{
        type: ERROR_CONTROL,
        payload: payload,
        message: message,
        alertColor: alertColor,

    }
}