import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import icono from './assets/img/icono5.png';
import customer from './assets/img/customer.png';
import { stylesServices } from '../constants.js';


/*Animations*/
import Fade from 'react-reveal/Fade';

export class customerExperience extends Component {
    render() {
        const { classes } = this.props
        return (
            <Grid container id='client' item xs={12} >
                <Grid container style={{ marginBottom: '10vh' }}>
                    <Grid xs={12} sm={12} md={7} />
                    <Grid item xs={12} sm={7} md={7} container alignItems='center' className={classes.customer}>
                        <Fade bottom cascade>
                            <img src={customer} alt='img' className={classes.imgSoftware} style={{ marginTop: '10vh' }} />
                        </Fade>
                    </Grid>

                    {/* <Grid xs={12} sm={7} md={7} /> */}
                    <Grid xs={12} sm={5} md={5} className={classes.contenedor}>
                        <Grid container>
                            <Grid xs={12} sm={12} md={2} />
                            <Grid xs={12} sm={12} md={5}
                                justify='center'>
                                <Fade bottom cascade>
                                    <img src={icono} alt='img' width='150' className={classes.iconoCustomer} />
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={12} md={1} />

                            <Grid xs={12} sm={11} md={12} className={classes.titleSoftware}>
                                <Fade right cascade>
                                    <Typography style={{ color: 'white' }} className={classes.title}>Experiencia al Cliente</Typography>
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={1} md={1} />

                            <Grid xs={12} sm={11} md={10} container justify='flex-end' alignItems='center' className={classes.TypographyCustomer} >
                                <Fade right cascade>
                                    <Typography className={classes.text} style={{ color: 'white' }}>Ofrecemos servicio de asistencia técnica local
                                    o remota, en el momento requerido por cada cliente. Adiestramos al personal
                                en cuanto a manejo eficiente de software y equipos de computación.</Typography>
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={1} md={2} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const CustomerExperience = withStyles(stylesServices, { withTheme: true })(customerExperience);
export default CustomerExperience
