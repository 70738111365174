import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import software from './assets/img/software.png';
import icono from './assets/img/icono1.png';
import { stylesServices } from '../constants.js';

/*Animations*/
import Fade from 'react-reveal/Fade';

export class Software extends Component {
    render() {
        const { classes } = this.props
        return (
            <Grid container id='software' item xs={12} style={{ marginTop: '5vh' }}>
                <Grid container>
                    <Grid xs={12} sm={12} md={7} container alignItems='center'>
                        <Fade bottom cascade>
                            <img src={software} alt='img' className={classes.imgSoftware} />
                        </Fade>
                    </Grid>
                    <Grid xs={12} sm={12} md={5} >
                        <Grid container>
                            <Grid xs={12} sm={12} md={2} />
                            <Grid xs={12} sm={12} md={5}
                                justify='center'>
                                <Fade right cascade>
                                    <img src={icono} alt='img' width='150' className={classes.iconoSoftware} />
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={12} md={1} />

                            <Grid xs={12} sm={11} md={12} className={classes.titleSoftware}>
                                <Fade right cascade>
                                    <Typography style={{ color: '#4D4D4D' }} className={classes.title}>Fabrica de Software</Typography>
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={1} md={1} />

                            <Grid xs={12} sm={11} md={12} container className={classes.TypographySoftware} >
                                <Fade right cascade>
                                    <Typography className={classes.text}>Diseñamos y construimos sistemas informáticos
                                    de calidad, atendiendo a las necesidades y
                                    especificaciones de tu empresa, generando una 
                                   experiencia satisfactoria para el usuario y una 
                                    sencilla implementación</Typography>
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={1} md={12} />
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        )
    }
}

const softwareIT = withStyles(stylesServices, { withTheme: true })(Software);
export default softwareIT
