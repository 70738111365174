

export const styles = (theme) => ({
  

    floatButtom: {
        width: 50,
        height: 50,
        backgroundColor: "#11a3e6",
        position: 'fixed',
        boxShadow: '1px 2px 2px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 40

        },
    },
    rightMenu: {
        marginTop: "10vh",
        position: "fixed",
        color: "white",
        textDecoration : "none",
        borderRadius: 5,
    
        backgroundColor: "#3f51b582",
        [theme.breakpoints.down('xs')]: {
            display: "none"

        },
        [theme.breakpoints.up('md')]: {
            display: "block"

        },
    },


});