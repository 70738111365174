import { OPEN_CONTACT} from "../constants";


export const contactReducer = (state = {}, action) =>{
    switch(action.type){

        case OPEN_CONTACT:
            return{ ...state,
                contactPayload: action.payload
            };
    default:
        return state; 
    }
}