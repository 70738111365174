export const styles = (theme) => ({
    title: {
        fontWeight: "bold",
        [theme.breakpoints.down('sm')]: {
            fontSize: "40px",
            textAlign: "center",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "55px"
        },

    },
    teamContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "20vh",
            marginBottom: "25vh"
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "20vh",
            marginBottom: "25vh"
        },

    },

    boxData: {
        backgroundColor: "#1a237e",
        color: "white",
        [theme.breakpoints.down('sm')]: {    
            padding: 40,
            marginLeft: 0,
            marginTop: 0,
            textAlign:"center"
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 40,
            paddingRight: 60,
            paddingBottom: 40,
            position: "absolute",
            width: 400,
            marginLeft: -100,
            marginTop: 310
        }
    }

});