import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Typography, withStyles } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import logo from "./assets/img/logo.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Alert from "@material-ui/lab/Alert";
import Slide from '@material-ui/core/Slide';



/*ACCIONES*/
import { openContact } from "../../../Redux/Actions/_actionContacto";
import { postContacto } from "../../../Redux/Actions/Services/postContacto";
import { errorControl } from "../../../Redux/Actions/_errorControl";
import { loader } from "../../../Redux/Actions/_actionLoader";

/*CONSTANTES*/
import { styles } from "./constants";
import "./index.css";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      value: 0,
      name: "",
      email: "",
      phone: "",
      commentary: "",
      boolEmail: false,
      boolName: false,

    };
  }

  /*FUNCIONES*/

  componentDidMount() {
    this.props.loader(true);
  }

  /**
   * @param {*}
   * validateInput , funcion donde validamos cada input
   */
  validateInput = (name, value) => {
    switch (name) {
      case "name":
        var patterns = /^([^0-9-+_!/@#$%^&*.,?]*)$/;

        if (patterns.test(this.state.name)) {
          this.setState({
            boolName: false
          })
        } else {
          this.setState({
            boolName: true
          })
        }

        break;
      case "email":
        var patternsEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,9}$/i;

        if (patternsEmail.test(this.state.email)) {
          this.setState({
            boolEmail: false
          })
        } else {
          this.setState({
            boolEmail: true
          })
        }
        break;

      default:
        break;
    }
    if (
      this.state.name !== "" &&
      this.state.email !== "" &&
      this.state.phone !== "" &&
      this.state.commentary !== "" &&
      !this.state.boolEmail &&
      !this.state.boolName
    ) {
      this.props.loader(false);
    }
  };

  /**
   * @param {*}
   *
   */
  onChangeValues = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState({ [name]: value });

    this.validateInput(name, value);
  };

  /**
   * @param {*}
   * handleChange, funcion que cambia de un boton a otro.
   */
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  /**
   * @param {*}
   * handleClose, funcion que cierra el modal
   */
  handleClose = () => {
    this.props.openContact(false);
  };

  /**
     * @param {*}
     *
     * maxLengthCheck se encarga de limitar los caracteres que entrar por el input phone.
     */
  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  /**
   * @param {*}
   *
   * integrationContact: llamado al API envio de correo. "
   */
  integrationContact = () => {
    this.props.postContacto(
      this.state.name,
      this.state.email,
      this.state.phone,
      this.state.commentary
    );
    this.setState({ name: "", email: "", phone: "", commentary: "" });
  };


  handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.errorControl(false)
  };

  render() {
    const { classes } = this.props;
    // const { children, value, index } = this.props;
    return (
      <>
        <Dialog open={this.props.contactPayload} aria-labelledby="responsive-dialog-title" TransitionComponent={TransitionUp}>
          <DialogContent item xs={12} sm={6} md={6} lg={6}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container className={classes.contenedor}>
                      <Grid item xs={10} sm={10} md={10} lg={10}></Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Button onClick={this.handleClose}>
                          <CloseIcon className={classes.close1} />
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <img src={logo} alt="" className={classes.logo} />
                      </Grid>

                      <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10}>
                        <Typography
                          variant="h6"
                          style={{
                            // marginTop: "7vh",
                            color: "#182476",
                            position: "static",
                            textAlign: "center",
                          }}
                        >
                          ¿Quieres saber más?
                      </Typography>
                        <Typography
                          variant="h7"
                          style={{ position: "static", textAlign: "left" }}
                        >
                          Envíanos un mensaje usando el formulario o envíenos un
                          correo electrónico.
                      </Typography>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={1} className={classes.iconos}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>

                            <a
                              href="https://api.whatsapp.com/send?phone=573004736726"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i class="icon-whatsapp" style={{ color: "#aeb0b3", fontSize: 20 }} />
                            </a>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>

                            <a href="https://www.instagram.com/corporacionkmg/" target="_blank" rel="noopener noreferrer" >
                              <i class="icon-instagram" style={{ color: "#aeb0b3", fontSize: 20 }} />
                            </a>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>


                            <a href="https://www.linkedin.com/company/corporacion-kmg/" target="_blank" rel="noopener noreferrer"  >
                              <i class="icon-linkedin" style={{ color: "#aeb0b3", fontSize: 20 }} />
                            </a>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {/* <img src={Twitter} alt="" width="20" /> */}
                            <a href="https://twitter.com/CorporacionKmg/" target="_blank" rel="noopener noreferrer">
                              <i class="icon-twitter" style={{ color: "#aeb0b3", fontSize: 20 }} />
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        style={{
                          position: "relative",
                          top: "-105px",
                          left: "70px",
                        }}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            variant="h7"
                            style={{ marginTop: "5vh", position: "static" }}
                          >
                            Hola!
                          <br></br>
                          </Typography>
                          <Typography
                            variant="h7"
                            style={{
                              position: "static",
                              textAlign: "right",
                              borderBottom: "1px solid #006eff",
                            }}
                          >
                            corporacion@kmg.com.ve
                        </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container spacing={1} className={classes.contenedor2}>
                      <Grid item xs={10} sm={10} md={10} lg={10} />
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Button onClick={this.handleClose}>
                          <CloseIcon className={classes.close2} />
                        </Button>
                      </Grid>



                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          error
                          helperText={this.state.boolName && "Formato no válido"}
                          label="Nombre y Apellido*"
                          className={classes.textColor}
                          name="name"
                          type="text"
                          value={this.state.name}
                          onChange={(event) => {
                            this.onChangeValues(event);
                          }}
                          inputProps={{
                            maxLength: 35,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          id="outlined-textarea"
                          error
                          helperText={this.state.boolEmail && "Dirección de correo no válido"}
                          label="Correo*"
                          type="text"
                          className={classes.textColor}
                          name="email"
                          value={this.state.email}
                          onChange={(event) => {
                            this.onChangeValues(event);
                          }}
                          inputProps={{
                            maxLength: 35,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          id="formatted-numberformat-input"
                          label="Teléfono*"
                          className={classes.textColor}
                          name="phone"
                          type="number"
                          value={this.state.phone}
                          onChange={(event) => {
                            this.onChangeValues(event);
                          }}
                          onInput={this.maxLengthCheck}
                          inputProps={{

                            maxLength: 20
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          id="outlined-textarea"
                          label="Comentario*"
                          className={classes.textColor}
                          maxLength={250}
                          name="commentary"
                          type="text"
                          value={this.state.commentary}
                          onChange={(event) => {
                            this.onChangeValues(event);
                          }}
                          inputProps={{
                            maxLength: 255,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                          variant="outlined"
                          className={classes.button}
                          onClick={this.integrationContact}
                          disabled={this.props.loaderPayload}
                        >
                          Enviar
                      </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Tabs
                      aria-label="simple tabs example"
                      value={this.state.value}
                      indicatorColor="white"
                      onChange={this.handleChange}
                      className={classes.root}
                    >
                      <Tab label="Venezuela" />
                      <Tab label="Colombia" variant="contained" />
                    </Tabs>

                    {this.state.value === 0 && (
                      <iframe
                        title="Corporación KMG, C.A."
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15692.763562614455!2d-66.9430544!3d10.4856131!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a5f11280e9c6b%3A0x2ae1d50bf149b262!2sCentro%20Comercial%20Multiplaza%20Paraiso!5e0!3m2!1ses!2sve!4v1615471650870!5m2!1ses!2sve"
                        width="250"
                        height="300"
                        allowfullscreen=""
                        loading="lazy"
                        className={classes.googleMap}
                      ></iframe>
                    )}
                    {this.state.value === 1 && (
                      <iframe
                        title="Corporación KMG, C.A."
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7952.860633132458!2d-74.049102!3d4.695053!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9ab9e9c9689d%3A0x1d0a26551d7edc13!2sCl.%20109a%20%2318-51%2C%20Bogot%C3%A1%2C%20Colombia!5e0!3m2!1ses!2sve!4v1615582687412!5m2!1ses!2sve"
                        width="400"
                        height="300"
                        className={classes.googleMap}
                        allowfullscreen=""
                        loading="lazy"
                      ></iframe>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>




        <Snackbar onClose={this.handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.props.errorControlPayload} autoHideDuration={6000} TransitionComponent={TransitionUp} >
          <Alert severity={this.props.errorControlAlertColor} onClose={this.handleCloseAlert}>
            {this.props.errorControlMessage}
          </Alert>
        </Snackbar>


      </>
    );
  }
}

const contacto = withStyles(styles, { withTheme: true })(Contacto);
function mapStateToProps(state) {
  return {
    contactPayload: state.contactReducer.contactPayload,
    errorControlPayload: state.errorControl.errorControlPayload,
    errorControlMessage: state.errorControl.errorControlMessage,
    errorControlAlertColor: state.errorControl.errorControlAlertColor,
    loaderPayload: state.loader.loaderPayload,
  };
}

export default connect(mapStateToProps, {
  openContact,
  postContacto,
  errorControl,
  loader,
})(contacto);
