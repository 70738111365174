export const styles = (theme) => ({

    title:{
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
            marginTop: -20,
            textAlign: "center"
        },
       
        [theme.breakpoints.up('md')]: {
            fontSize: "6rem"

        },
    },

    email:{
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            textAlign: "center"         
        },
       
        [theme.breakpoints.up('md')]: {
            fontSize: 35

        },
    },

    content:{
        color: "white", 
        marginTop: "30vh",
        [theme.breakpoints.down('xs')]: {
            padding: 50,
        },
    }
})