import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typical from 'react-typical';
import icono from './assets/img/icono4.png';
import robot from './assets/img/RPA.png';
import fondo from './assets/img/fondo2.jpg';
import { stylesServices } from '../constants.js';
import CustomerExperience from '../CustomerExperience';

/*Animations*/
import Fade from 'react-reveal/Fade';

export class Rpa extends Component {
    render() {
        const { classes } = this.props
        return (
            <>
                <Grid container id='rpa' item xs={12} style={{
                    marginTop: '5vh',
                    backgroundImage: 'url(' + fondo + ')',
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                }} >
                    <Grid container>
                        <Grid xs={12} sm={7} md={7} container alignItems='center'>
                            <Fade bottom cascade>
                                <img src={robot} alt='img' className={classes.imgSoftware} style={{ marginTop: '5vh' }} />
                            </Fade>
                        </Grid>
                        <Grid xs={12} sm={5} md={5} >
                            <Grid container>
                                <Grid xs={12} sm={12} md={2} />
                                <Grid xs={12} sm={12} md={5}
                                    justify='center'>
                                    <Fade bottom cascade>
                                        <img src={icono} alt='img' width='150' className={classes.iconoRPA} />
                                    </Fade>
                                </Grid>
                                <Grid xs={12} sm={12} md={1} />

                                <Grid xs={12} sm={11} md={12} className={classes.titleSoftware}>
                                    <Fade right cascade>
                                        <Typography style={{ color: 'white' }} className={classes.title}>RPA - Automatización
                                       Robótica de Procesos</Typography>
                                    </Fade>
                                </Grid>
                                <Grid xs={12} sm={1} md={1} />

                                <Grid xs={12} sm={11} md={12} container justify='flex-end' alignItems='center' className={classes.TypographySoftware} >
                                    <Fade right cascade>
                                        <Typography className={classes.text} style={{ color: 'white' }}>Replicamos las interacciones de un ser humano con la interfaz de usuario de una aplicación, a través de una naciente técnica de automatización de procesos que cada día toma mayor impulso y brinda a nuestros clientes optimas alternativas para el manejo de tareas repetitivas</Typography>
                                    </Fade>
                                </Grid>
                                <Grid xs={12} sm={1} md={12} />
                            </Grid>
                        </Grid>

                        <Grid xs={12} sm={12} md={12} >
                            <CustomerExperience />
                        </Grid>
                    </Grid>
                </Grid>

                {/* TEXTO EN MOVIMIENTO */}
                <Grid container item xs={12} >
                    <Grid container>
                        <Grid xs={12} sm={12} md={12} container justify='center' alignItems='center' className={classes.move}>
                            <Typography className={classes.TypographyMove}>
                                <Typical
                                    steps={['DESARRO', 1000, 'DESARROLLAMOS BASES TECNOLÓGICAS', 500]}
                                    loop={Infinity}
                                    wrapper="p"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const RPA = withStyles(stylesServices, { withTheme: true })(Rpa);
export default RPA
