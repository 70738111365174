import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Footer from '../Footer';
import Header from '../Header';

export class PrivacyPolicies extends Component {
    render() {
        return (
            <>
                <Header logo={'#33aadb'} />
                <Grid container style={{ marginTop: 50, marginBottom: 50 }}>
                    <Grid item xs={0} sm={4}>

                    </Grid>
                    <Grid item xs={0} sm={4}>

                        <strong>Política de Privacidad</strong><br /><br />

                        <strong>Última actualización: 01/05/2024</strong><br />
                        En <strong>Corporación KMG, c.a.</strong>, respetamos su privacidad y estamos comprometidos a protegerla a través de nuestro cumplimiento de esta política.<br /><br />

                        <strong>Información que No Recopilamos</strong><br />
                        Hasta el momento, no recopilamos ninguna información personal de nuestros usuarios. Esto incluye, pero no se limita a, nombres, direcciones de correo electrónico, números de teléfono y cualquier otra información que pueda identificar personalmente a un individuo.<br /><br />

                        <strong>Cambios a Esta Política de Privacidad</strong><br />
                        Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Publicaremos cualquier cambio en esta página, y la fecha de la última actualización se indicará en la parte superior de la política. Le recomendamos revisar periódicamente esta Política de Privacidad para estar informado sobre cómo estamos protegiendo su información.<br /><br />

                        <strong>Contacto</strong><br />
                        Si tiene alguna pregunta o inquietud sobre esta Política de Privacidad, puede contactarnos a través de:<br /><br />
                        •	<strong>Correo Electrónico:</strong> corporacion@kmg.com.ve<br />
                        •	<strong>Dirección:</strong> Caracas - Venezuela<br />
                        •	<strong>Número de WhatsApp:</strong> +58 412 627 0000<br /><br />

                        <strong>Conformidad Legal</strong><br />
                        Nos comprometemos a cumplir con todas las leyes y regulaciones aplicables en materia de privacidad y protección de datos.<br />
                    </Grid>
                    <Grid item xs={0} sm={4}>

                    </Grid>



                </Grid >
                <Footer />
            </>
        )
    }
}

export default PrivacyPolicies

