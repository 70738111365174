import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

import DIGITEL from './assets/img/digitel.svg';
import SERVINGCA from './assets/img/servingca.svg';
import BSA from './assets/img/bsa.svg';
import NEMESIS from './assets/img/nemesis.png';
import { styles } from './constants';

/*Animatins*/
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

class customers extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.paddingContainer}>
                <Grid item xs={12} style={{ marginBottom: 50, marginTop: -40 }}>
                    <Fade bottom>
                        <Typography className={classes.typografyData}><spam style={{ color: "#61dafb" }}>&#8213;&#160;</spam>Clientes</Typography>
                    </Fade>
                </Grid>
                <Grid item xs={12} spacing={2}>
                    <Grid container style={{ textAlign: "center" }}>
                        <Grid item xs={12} md={3}>
                            <Zoom left >
                                <img src={DIGITEL} alt="Corporación Digitel" width="200" />
                            </Zoom>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Zoom left>
                                <img src={SERVINGCA} alt="Corporación Digitel" width="200" />
                            </Zoom>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Zoom left>
                                <img src={BSA} alt="Corporación Digitel" width="200" />
                            </Zoom>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Zoom left>
                                <img src={NEMESIS} alt="Corporación Digitel" width="200" style={{marginTop:15}} />
                            </Zoom>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
const Customers = withStyles(styles, { withTheme: true })(customers);
export default Customers;