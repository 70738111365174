import { LOADER } from "../constants";

export const loader = (state = {} ,action) => {

    switch (action.type) {
        
        case LOADER:
            return {...state, 
                loaderPayload: action.payload
            }   

        default:
            return state;
    }
}