import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { styles } from './constants'

/* Assets */
import logoCompleto from './assets/img/logo.png';

/*Animations*/
/*Animations*/
import Fade from 'react-reveal/Fade';

function mapStateToProps(state) {
    return {

    };
}

class innovationFriends extends Component {
    render() {
        const { classes } = this.props;
        return (
            <>
                <Fade bottom>
                    <div>
                        <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: "20vh" }}>
                            <img src={logoCompleto} className={classes.logo} alt="logo completo KMG" />
                        </Grid>

                        <Grid container direction="row" alignItems="center" style={{ marginBottom: "23vh", padding: 50 }}>
                            <Grid item xs={12} style={{ textAlign: "center", color: "#0a2a4a" }}>
                                <Typography className={classes.title} id="InnovationTitle">
                                    Tu mejor aliado
                        </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography className={classes.text} >
                                    Nuestros profesionales te ayudarán a definir el software que necesitas
                        </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography className={classes.text}>
                                    siguiento las mejores prácticas para garantizar la seguridad de los datos, un
                        </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography className={classes.text} >
                                    optimo performance y la mejor experiencia de usuario para tus clientes.
                        </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </>
        );
    }
}

const InnovationFriends = withStyles(styles, { withTheme: true })(innovationFriends);

export default connect(
    mapStateToProps,
)(InnovationFriends);