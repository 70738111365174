export const styles = (theme) => ({
    root: {
        background: '#e6e7e7',
        marginTop: '-30vh',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    rootMobile: {
        background: '#e6e7e7',
        marginTop: '-30vh',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    carouselItem: {
        background: '#999999a1',
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: "80vh", 
        },
        [theme.breakpoints.down('xs')]: {
            height: "70vh", 
        },
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
      },
      img: {
        height: 255,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
      },

    ilustracion1: {
        padding: '1%',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(-50, 0, 0, 20),
        },
    },
    ilustracion2: {
        padding: '1%',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0, 0, -20, 17),
            zIndex: '100'
        },
    },
    ilustracion3: {
        padding: '5% 1% 1% 1%',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(-60, 0, 0, 20),
        },
    },
    ilustracion4: {
        padding: '1%',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0, 0, -20, 17),
            zIndex: '100'
        },
    },
    ilustracion5: {
        padding: '5% 1% 1% 1%',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(-55, 0, 0, 20),
        },
    },
});
