export const styles = (theme) => ({
    appBar: {
        backgroundColor: "#00000000",
        color: "black",
        boxShadow: "none",
        position: "absolute",
    },
    iconLogo: {
        fontSize: 28,
        color: "#198dc3"
    },
    topMenu: {
        color: "white",
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
        [theme.breakpoints.up('md')]: {

        },
        [theme.breakpoints.up('lg')]: {

        },
    },
    topMenuOptions: {
        textTransform: 'none',
        borderRadius: "50px",
    },
    optionStyle: {
        color: 'inherit',
        textDecoration: 'none'
    },
    contactStyle: {
        textTransform: 'none',
        border: "1px solid white",
        borderRadius: "50px",
        padding: "5px 15px 5px 15px"
    },
    sideMenu: {
        marginTop: "55vh",
        position: "fixed"
    },
    sideMenuOption: {
        marginLeft: 20,
        marginBottom: 20,
        width: 45,
    },
    principalTitle: {
        marginTop: "28vh",
        position: "absolute",
        color: 'white',
        [theme.breakpoints.up('lg')]: {
            fontSize: theme.spacing(12),
            marginRight: "18vh",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(8),
            marginRight: "18vh",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(6),
            marginRight: "5vh",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(4),
            marginRight: "0vh",
        },
    },
    titleDecoration: {
        color: '#4884cc',
        textDecoration: 'underline',
        textDecorationColor: 'white'
    },
    secondTitle: {
        [theme.breakpoints.up('lg')]: {
            marginTop: "80vh",
            position: "absolute",
            color: 'white',
            display: 'flex',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: "80vh",
            position: "absolute",
            color: 'white',
            display: 'flex',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "80vh",
            position: "absolute",
            color: 'white',
            display: 'flex',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "90vh",
            position: "absolute",
            color: 'white',
            display: 'flex',
        },
    },
    backgroundPrincipal: {
        background: '#0e0345',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh"
    },

    floatMenu: {
        marginTop: "10vh",
        position: "fixed",
        color: "white",
        textDecoration: "none",
        [theme.breakpoints.down('xs')]: {
            marginLeft:  theme.spacing(-8)
        },
    },
    floatMenuOptions: {
        borderRadius: 50,
    },
    floatMenuOptionsStyle: {
        color: 'inherit',
        textDecoration: 'none',
    },

    dialogMessage: {
        [theme.breakpoints.up('lg')]: {
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginTop: "70vh",
            marginLeft: "-18vh",
            height: "22vh",
            position: 'relative',
        },
        [theme.breakpoints.down('md')]: {
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginTop: "70vh",
            marginLeft: "-18vh",
            height: "22vh",
            position: 'relative',
        },
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(18),
            height: theme.spacing(14),
            marginLeft: theme.spacing(-16),
        },
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(18),
            height: theme.spacing(14),
            marginLeft: theme.spacing(-16),
        },
    },

    burbbleFloat: {
        [theme.breakpoints.up('lg')]: {
            width: 50,
            marginTop: "-4vh",
            marginLeft: 40,
            backgroundColor: "#11a3e6",
            boxShadow: '1px 2px 2px',
        },
        [theme.breakpoints.down('md')]: {
            width: 50,
            marginTop: "-4vh",
            marginLeft: 40,
            backgroundColor: "#11a3e6",
            boxShadow: '1px 2px 2px',
        },
        [theme.breakpoints.down('sm')]: {
            width: 50,
            marginLeft: theme.spacing(-6),
            marginTop: theme.spacing(-1),
            backgroundColor: "#11a3e6",
            boxShadow: '1px 2px 2px',
        },
        [theme.breakpoints.down('xs')]: {
            width: 50,
            marginLeft: theme.spacing(-6),
            marginTop: theme.spacing(-1),
            backgroundColor: "#11a3e6",
            boxShadow: '1px 2px 2px',
        },
    },

    burbble1: {
        // float: 'inherit',
        position: 'relative',
        margin: '80vh 0% 0% 90%',
        width: '10vw',
        height: '10vw',
        opacity: '0.5',
        zIndex: '-1',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        animationTimingFunction: 'ease-in-out',
        animationDuration: '6s',
        animationDelay: '0.5s',
        animationIterationCount: 1,
        animationDirection: 'normal',
        animationFillMode: 'forwards',
    },
    burbble2: {
        // float: 'inherit',
        position: 'relative',
        margin: '0vh 0% 0% 80%',
        width: '10vw',
        height: '10vw',
        zIndex: '-1',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        animationTimingFunction: 'ease-in-out',
        animationDuration: '6s',
        animationDelay: '0s',
        animationIterationCount: 1,
        animationDirection: 'normal',
        animationFillMode: 'forwards',
    },
    burbble3: {
        // float: 'inherit',
        position: 'relative',
        margin: '-120vh 0% 0% 0%',
        width: '7vw',
        height: '7vw',
        zIndex: '-1',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        animationTimingFunction: 'ease-in-out',
        animationDuration: '5s',
        animationDelay: '0s',
        animationIterationCount: 1,
        animationDirection: 'normal',
        animationFillMode: 'forwards',
    },
    burbble4: {
        // float: 'inherit',
        position: 'relative',
        margin: '90vh 0% 0% 30%',
        width: '7vw',
        height: '7vw',
        opacity: '0.1',
        zIndex: '-1',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        animationTimingFunction: 'ease-in-out',
        animationDuration: '5s',
        animationDelay: '0.5s',
        animationIterationCount: 1,
        animationDirection: 'normal',
        animationFillMode: 'forwards',
    },
});
