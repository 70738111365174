import background from './assets/img/fondo.png';

export const styles = (theme) => ({
    root: {
        flexGrow: 1,

    },
    appBarPosition:{
        [theme.breakpoints.down('sm')]: {
            position: "fixed"
        },
        [theme.breakpoints.up('md')]: {
            position: "absolute"
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    color: {
        backgroundColor: "#00000000 !important"
    },
    menuData: {
        textTransform: 'none',
        color: "white",

        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
        [theme.breakpoints.up('md')]: {

        },
        [theme.breakpoints.up('lg')]: {

        },

    },
    marginText: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "60vh"
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "30vh"
        },
    },
    typographyCover: {
        marginTop: "-30px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "40px",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "60px"

        },
    },
    typography: {
        marginTop: "0px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px"

        },
        [theme.breakpoints.up('md')]: {
            fontSize: "30px"

        },
    },
    icons: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,

        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 20,
            marginBottom: 10,
            width: 45
        },
    },
    rightPanel: {
        height: "100vh",
        [theme.breakpoints.down('xs')]: {
            backgroundColor: "white",
            marginTop: "-100vh",
            zIndex: -5
        },
        [theme.breakpoints.down('md')]: {
            backgroundImage: 'url(' + background + ')',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left bottom",

        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: 'url(' + background + ')',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left bottom",

        },

    },
    leftPanel: {
        height: "100vh",
        [theme.breakpoints.down('xs')]: {
            color: "#11a3e6",
            zIndex: 5
        },

    },
    floatButtom: {
        width: 50,
        height: 50,
        backgroundColor: "#11a3e6",
        position: 'fixed',
        boxShadow: '1px 2px 2px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 40

        },
    },
    rightMenu: {
        marginTop: "10vh",
        position: "fixed",
        color: "white",
        textDecoration : "none",
        [theme.breakpoints.down('xs')]: {
            marginLeft: -30,

        },
    },
    floatWorld: {
        [theme.breakpoints.down('xs')]: {
            animation: "floatTop",
            animationDuration: "6s",
            animationIterationCount: "infinite",
            marginTop: 20
        },
        [theme.breakpoints.up('md')]: {
            animation: "floatRight",
            animationDuration: "6s",
            animationIterationCount: "infinite",
        },
    },
    menuIcon:{
        float: "right",
        [theme.breakpoints.down('xs')]: {
           display:"block"
        },
        [theme.breakpoints.up('md')]: {
            display:"none"
        },
    }

});