export const styles = (theme) => ({

    serviceContainer: {
        backgroundColor: "#030a52",
        height: "auto",
        marginTop: "280vh"
    },

    textScroll: {
        color: "transparent",
        marginTop: 100,
        textAlign: "left",
        "-webkit-text-stroke": "2px white",
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            // animation: "traslate",
            // animationDuration: "4s",
            // animationIterationCount: "infinite"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 100,
            // animation: "traslate",
            // animationDuration: "4s",
            // animationIterationCount: "infinite"

        },
    },
    textScrollR: {
        color: "transparent",
        marginTop: 100,
        textAlign: "left",
        "-webkit-text-stroke": "2px white",
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            // animation: "traslateR",
            // animationDuration: "4s",
            // animationIterationCount: "infinite"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 100,
            // animation: "traslateR",
            // animationDuration: "4s",
            // animationIterationCount: "infinite"

        },
    },
    serviceImg: {

        [theme.breakpoints.down('sm')]: {
  
            marginTop: "-20px",
            width: "80px",
            height: "80px",
        },
        [theme.breakpoints.up('md')]: {
          
            marginTop: 20,
            width: "100px",
            height: "100px",

        },
    },
    marginBox: {
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: -10,
            marginRight: 100

        },
    },
    containerBox: {
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "20vh",
            marginLeft: -100

        },
    },




})