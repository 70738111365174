import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';


/* Components */
import Header from '../Header';
import Message from './Message'
import Info from './Info'
import Benefits from './Benefits'
import Carrousel from './Carrousel'
import Allies from './Allies'
import Footer from '../Footer'
class About extends Component {
    render() {
        return (
            <Grid container>
                <Header logo={'#198dc3'} />
                <Message />
                <Info />
                <Benefits />
                <Carrousel />
                <Allies />
                <Footer />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}

export default connect(
    mapStateToProps,
)(About);