export const styles = (theme) => ({
    title: {
        color: '#999999',
        display: 'flex',
        marginTop: '5%',
        [theme.breakpoints.down('xs')]: {
            marginTop: '15%',
        },
    },
    titlePrincipal: {
        textAlign: "center",
        color: "#0a2a4a",
        [theme.breakpoints.up('xs')]: {
            fontSize: theme.spacing(1),
        },
    },
    titleSize: {
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(5),
        },
    },
    logosStyle: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(4),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(4),
        },
    },

});
