import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import it from './assets/img/it.png';
import { connect } from "react-redux";

import { openContact } from '../../../Redux/Actions/_actionContacto';


import { stylesServices } from '../constants.js';
import RightMenu from '../../Home/RightMenu';

/*Animations*/
import Fade from 'react-reveal/Fade';


export class IT extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            color: "white"
        }
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var y = window.scrollY;
        // alert(y)
        if (y > 650) {
            this.setState({
                show: true
            })
        } else {
            this.setState({
                show: false
            })
        }

        var color = "";
        if (y > 600) {
            color = "black";
            if (y > 2000) {
                color = "white";
            }
        } else {
            color = "white";
        }

        this.setState({
            color: color
        })
    }


    handleContact = () => {
        this.props.openContact(true);
    };

    render() {
        const { classes } = this.props
        return (
            <Grid container id='it' item xs={12} style={{ marginTop: '10vh' }}>
                <Grid container>
                    <Grid xs={12} sm={1} md={3} />
                    <Grid xs={12} sm={10} md={7} container justify='center' alignItems='center'>
                        <Fade left cascade>
                            <img src={it} alt='img' style={{ objectFix: 'cover', width: '100%' }} />
                        </Fade>
                    </Grid>
                    <Grid xs={12} sm={1} md={2} />
                    <Grid item xs={12} sm={12} md={12} className={classes.menuRight}>
                        <RightMenu />
                    </Grid>
                    <Grid xs={12} sm={2} md={2} />
                    <Grid xs={12} sm={10} md={10} container alignItems='center' className={classes.TypographyIT}>
                        <Fade left cascade>
                            <Typography style={{ color: '#4D4D4D' }} className={classes.title}>Proyectos y Consultoria TI</Typography>
                        </Fade>
                    </Grid>
                    <Grid xs={12} sm={2} md={2} />
                    <Grid xs={12} sm={9} md={8} container alignItems='center' className={classes.TypographySubtitle} >
                        <Fade left cascade>
                            <Typography className={classes.text}>Basados en nuestra experiencia en el área, ofrecemos asesoramiento para que nuestros 
                           clientes implementen soluciones tecnológicas y optimicen tus procesos de negocio</Typography>
                        </Fade>
                    </Grid>
                    <Grid xs={12} sm={1} md={2} />
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        contactPayload: state.contactReducer.contactPayload,

    };
}
const It = withStyles(stylesServices, { withTheme: true })(IT);


export default connect(mapStateToProps, {
    openContact,
})(It);

