import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

import Header from '../Header';
import HeaderServices from './Header';
import IT from './IT';
import Software from './Software';
import TechnologicalInfrastructure from './TechnologicalInfrastructure';
import GraphicDesign from './GraphicDesign';
import RPA from './RPA';
import Footer from '../Footer';

export class Service extends Component {
    render() {
        return (
            <Grid container>
                <Header logo={'white'}/>
                <HeaderServices />
                <IT />
                <Software />
                <TechnologicalInfrastructure />
                <GraphicDesign />
                <RPA />
                <Footer />

            </Grid>
        )
    }
}

export default Service

