import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import admin from './assets/img/Administration.gif';
import { stylesServices } from '../constants.js';

/*Animations*/
import Fade from 'react-reveal/Fade';

export class delegatedAdministration extends Component {
    render() {
        const { classes } = this.props
        return (

            <Grid container id='admin' item xs={12} md={12} sm={12} className={classes.fondoTecno}>
                <Grid container  >

                    <Grid xs={12} sm={2} md={2} />
                    <Grid xs={12} sm={7} md={7}  >
                        <Fade bottom cascade>
                            <img src={admin} alt='img' className={classes.fondoAdmin} />
                        </Fade>
                    </Grid>
                    <Grid xs={12} sm={3} md={3} />

                    <Grid xs={12} sm={4} md={4} />
                    <Grid xs={12} sm={7} md={7} className={classes.titleAdmin} style={{ textAlign: 'right' }}>
                        <Fade right cascade>
                            <Typography style={{ color: 'white', }} className={classes.title}>Administración Delegada - 
                            Continuidad Operativa</Typography>
                        </Fade>
                    </Grid>
                    <Grid xs={12} sm={1} md={1} />


                    <Grid xs={12} sm={2} md={2} />
                    <Grid xs={12} sm={9} md={9} className={classes.TypographyAdmin} style={{ color: 'white', textAlign: 'right' }}>
                        <Fade right cascade>
                            <Typography className={classes.text}>Ofrecemos estabilidad a través de herramientas tecnológicas, para un mayor
                                        rendimiento y corrección de eventos sin interrupción de los procesos
                            </Typography>
                        </Fade>
                    </Grid>
                    <Grid xs={12} sm={1} md={1} />
                    {/* </Grid> */}
                </Grid>
            </Grid>
        )
    }
}

const DelegatedAdministration = withStyles(stylesServices, { withTheme: true })(delegatedAdministration);
export default DelegatedAdministration
