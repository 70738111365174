
import { errorControl} from "../_errorControl";
import { loader }  from "../_actionLoader";


const properties = JSON.parse(localStorage.getItem("properties"));


export const postContacto = (name, email, phone, commentary) => {

    return dispatch => {
        dispatch(  loader(true) )
        
        var body = {
            "comentario": commentary
        }
         

        fetch(properties.endpoints.EnviarCorreo,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                 nombre: name,
                 correo: email,
                 telefono: phone,
            },
            body: JSON.stringify(body)

        })
        .then((res) => res.json())
        .then((data) => {

            // dispatch(  loader(false) )
            if(data.codRespuesta === 0){
                
                dispatch(errorControl(true, data.func_msg, 'success'))
            }else{
                dispatch(errorControl(true, data.func_msg, 'error'))
            }

        }).catch(() => {

            dispatch(errorControl(true, 'Ocurrió un error, intente de nuevo', 'error'))

        })








    }
}