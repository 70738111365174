export const styles = (theme) => ({
    principalText: {
        marginBottom: "23vh",
        marginTop: "30vh",
        textAlign: "center",
 
        [theme.breakpoints.down('xs')]: {
            marginBottom: "23vh",
            marginTop: "15vh",
            textAlign: "center",
           
        },
    },
    title: {
        [theme.breakpoints.up('lg')]: {
            fontSize: theme.spacing(7),
            color: "#0a2a4a"
        },
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(7),
            color: "#0a2a4a"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(5),
            color: "#0a2a4a"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(5),
            color: "#0a2a4a"
        },
    },
    generalTextStyle: {
        textAlign: "center",
        color: "#999999",
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(0, 22, 0, 22),
        },
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 10, 0, 10),
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 10, 0, 20),
        },
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(3),
            fontSize:10
        },
    },
    squareText1: {
        [theme.breakpoints.up('lg')]: {
            background: 'linear-gradient(to right, #0e0345 98%, #4884cc 2%)',
            width: "35em",
            height: '15em',
            marginTop: '-40vh',
            marginLeft: '25vh',
            marginBottom: '10%',
        },
        [theme.breakpoints.down('md')]: {
            background: 'linear-gradient(to right, #0e0345 98%, #4884cc 2%)',
            width: "80%",
            height: '15em',
            marginLeft: theme.spacing(12),
            marginTop: theme.spacing(-70),
            marginBottom: '0%',
        },
        [theme.breakpoints.down('sm')]: {
            background: 'linear-gradient(to right, #0e0345 98%, #4884cc 2%)',
            width: "80%",
            height: '15em',
            marginLeft: theme.spacing(10),
            marginTop: theme.spacing(0),
            marginBottom: '10%',
        },
        [theme.breakpoints.down('xs')]: {
            background: 'linear-gradient(to right, #0e0345 98%, #4884cc 2%)',
            width: "100%",
            height: '15em',
            marginBottom: '10%',
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(0),

        },
    },
    
    squareText2: {
        [theme.breakpoints.up('lg')]: {
            background: 'linear-gradient(to right, #0e0345 98%, #4884cc 2%)',
            width: "35em",
            height: '15em',
            marginTop: '-80vh',
            marginLeft: '-10vh',
        },
        [theme.breakpoints.down('md')]: {
            background: 'linear-gradient(to right, #0e0345 98%, #4884cc 2%)',
            width: "80%",
            height: '15em',
            marginLeft: theme.spacing(12),
            marginTop: theme.spacing(-30),
            marginBottom: '10%',
        },
        [theme.breakpoints.down('sm')]: {
            background: 'linear-gradient(to right, #0e0345 98%, #4884cc 2%)',
            width: "80%",
            height: '15em',
            marginLeft: theme.spacing(10),
            marginTop: theme.spacing(0),
            marginBottom: '20%',
        },
        [theme.breakpoints.down('xs')]: {
            background: 'linear-gradient(to right, #0e0345 98%, #4884cc 2%)',
            width: "100%",
            height: '15em',
            marginBottom: '25%',
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(0),
        },
    },
   
});
