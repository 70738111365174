import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './constants';

/* Assets */
import laptop from './assets/img/laptop.png';
import team from './assets/img/team.jpg';
import engranaje from './assets/img/engranaje.svg';

/*Animatins*/
import Fade from 'react-reveal/Fade';

class aboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            TextDinamic: 'Tecnología',
            TextDinamic2: 'a tu alcance',
        })
    }


    componentDidMount() {
        setInterval(this.changePrincipalTitle.bind(this), 3000);
    }

    changePrincipalTitle = () => {
        let arr = ['Tecnología', 'Optimizamos', 'Desarrollamos']
        let arr2 = ['a tu alcance', 'procesos', 'soluciones']
        let index;
        arr.forEach((element, i) => {
            if (this.state.TextDinamic === element) {
                if (arr.indexOf(element) === 2) {
                    index = -1;
                } else {
                    index = i;
                }
            }
        });
        this.setState({ TextDinamic: arr[index + 1], TextDinamic2: arr2[index + 1] });
    }


    render() {
        const { classes } = this.props;
        return (

            <Grid item xs={12} >

                <Grid container style={{
                    backgroundImage: 'url(' + engranaje + ')',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",

                    height: "auto",
                    position: "absolute",

                    marginTop: "5vh",

                }}>
                    {/* Carousel */}
                    <Grid item xs={12} sm={1} />

                    <Grid item xs={12} sm={10}>
                        <Fade bottom cascade>
                            <Grid container style={{ padding: 50 }}>
                                <Grid item xs={12} sm={4} style={{ marginTop: "55vh", color: "#1a237e" }}>
                                    <Typography className={classes.title}  >{this.state.TextDinamic}</Typography>
                                    <Typography className={classes.title} >{this.state.TextDinamic2}</Typography>

                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <img className="floatContainerTop" src={laptop} alt="laptop" style={{
                                        marginTop: "15vh",
                                        width: "100%",
                                        objectFit: "cover"
                                    }} />
                                </Grid>
                            </Grid>
                        </Fade>
                    </Grid>

                    <Grid item xs={12} sm={1} />

                    {/* Team */}
                    <Grid item xs={12} sm={3} />

                    <Grid item xs={12} sm={8} className={classes.teamContainer}>
                        <Grid item xs={12} sm={5} >
                            <Fade left cascade >
                                <Box style={{ zIndex: 999999999 }} className={classes.boxData}>
                                    <Typography style={{ textAlign: "right", fontSize: 25 }} ><i> Somos un equipo en constante evolución, enfocado en la excelencia y alta calidad de nuestros productos </i></Typography>
                                    <Typography style={{ textAlign: "right", fontSize: 25 }}><spam style={{ color: "#61dafb" }}>&#8213;&#160;</spam>Nosotros</Typography>
                                    <Typography style={{ textAlign: "right", fontSize: 15 }}>Integridad, honestidad y compromiso</Typography>
                                </Box>
                            </Fade>
                        </Grid>

                        <Grid item xs={12} sm={9}>
                            <Fade bottom cascade>
                                <img src={team} alt="team" style={{ width: "100%", objectFit: "cover" }}></img>
                            </Fade>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={1} />

                    <Grid xs={12} style={{ marginTop: "230vh" }}>

                    </Grid>
                </Grid>





            </Grid>
        );
    }
}
const AboutUs = withStyles(styles, { withTheme: true })(aboutUs);
export default AboutUs;