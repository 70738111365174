import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Technological from './assets/img/Technological.png';
import icono from './assets/img/icono2.png';

import DelegatedAdministration from '../DelegatedAdministration';
import { stylesServices } from '../constants.js';


/*Animations*/
import Fade from 'react-reveal/Fade';

export class TechnologicalInfrastructure extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Grid container item id='tecnoInfraestructura' xs={12} md={12} sm={12} className={classes.fondoTecno} >
                <Grid container style={{ marginBottom: '10vh' }}>
                    <Grid xs={12} sm={6} md={6} container alignItems='center' >
                        <Grid container className={classes.padding}>
                            <Grid xs={12} sm={12} md={12}
                                container
                                justify='center' style={{ marginTop: '5vh' }}>
                                <Fade bottom cascade>
                                    <img src={icono} alt='img' width='150' height= "150" className={classes.iconoTecno} />
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={1} md={2} />
                            <Grid xs={12} sm={11} md={8} >
                                <Fade left cascade>
                                    <Typography className={classes.TypographyTitle1}>Comercialización </Typography>
                                    <Typography className={classes.TypographyTitle2}>de Infraestructura</Typography>
                                    <Typography className={classes.TypographyTitle2}>Tecnológica</Typography>
                                </Fade>
                            </Grid>
                            <Grid xs={12} sm={1} md={2} />
                            <Grid xs={12} sm={1} md={2} />
                            <Grid xs={12} sm={11} md={10} container justify='flex-start' alignItems='center' className={classes.texto} >
                                <Fade left cascade>
                                    <Typography className={classes.text}>Somos proveedores de hardware a nivel nacional,
                                    damos asesoría en cuanto a la adquisición de equipos
                             de computación y herramientas</Typography>
                                </Fade>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid xs={12} sm={6} md={6} >
                        <Fade right cascade>
                            <img src={Technological} alt='img' className={classes.imgTecno} />
                        </Fade>

                    </Grid>


                    <Grid xs={12} sm={12} md={12} >
                        <DelegatedAdministration />
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}

const technologicalInfrastructure = withStyles(stylesServices, { withTheme: true })(TechnologicalInfrastructure);
export default technologicalInfrastructure
