import React, { Component } from 'react';
import { Grid, IconButton, Typography, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { stylesServices } from '../constants.js';

export class HeaderServices extends Component {
    constructor(props) {
        super(props)
        this.state = {

            items: [
                {
                    'id': 0,
                    'img': 'icon-service4',
                    'title': 'Administración Delegada - Continuidad Operativa'
                },
                {
                    'id': 1,
                    'img': 'icon-service5',
                    'title': 'Diseño Gráfico y Posicionamiento Web'
                },
                {
                    'id': 2,
                    'img': 'icon-service1',
                    'title': 'Proyectos y Consultoría TI'
                },
                {
                    'id': 3,
                    'img': 'icon-service7',
                    'title': 'Experiencia al Cliente'
                },
                {
                    'id': 4,
                    'img': 'icon-service3',
                    'title': 'Comercialización de Infraestructura Tecnológica'
                },
                {
                    'id': 5,
                    'img': 'icon-service2',
                    'title': 'Fabrica de Software'
                },
                {
                    'id': 6,
                    'img': 'icon-service6',
                    'title': 'RPA - Automatización Robótica de Procesos'
                }],
            active: 0,
            direction: '',
            level: 0,
        }
    }


    generateItems() {
        var items = []
        var level
        var menos = 0
        var mas = 0

        if (window.outerWidth > 959) {
            menos = 2;
            mas = 3;
        } if (window.outerWidth > 500 && window.outerWidth < 959) {
            menos = 1;
            mas = 2;
        } else if (window.outerWidth <= 500) {
            menos = 0;
            mas = 1;
        }
        for (var i = this.state.active - menos; i < this.state.active + mas; i++) {
            var index = i
            if (i < 0) {
                index = this.state.items.length + i
            } else if (i >= this.state.items.length) {
                index = i % this.state.items.length
            }
            level = this.state.active - i
            items.push(<Item key={index} id={this.state.items[index]} level={level} />)
        }
        var newActive = this.state.active
        var item = this.state.items
        setTimeout(
            function () {
                this.setState({
                    active: (newActive + 1) % item.length,
                    direction: 'right'
                })
            }
                .bind(this),
            3000
        );

        return items
    }


    render() {
        const { classes } = this.props;
        return (
            <>
                <Grid container className={classes.fondoHeader}>
                    <Grid item xs={1} sm={1} md={1} >
                        <Grid container alignContent="center" >
                            <Grid item xs={1} className={classes.redes} >

                                <Grid >
                                    <IconButton style={{ marginLeft: 20, marginBottom: 20, width: 45 }} id="redes" >
                                        <a href="https://api.whatsapp.com/send?phone=573004736726" target="_blank" rel="noopener noreferrer">
                                            <i class="icon-whatsapp" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                        </a>
                                    </IconButton>
                                </Grid>
                                <Grid>
                                    <IconButton style={{ marginLeft: 20, marginBottom: 20, width: 45 }} id="insta" >
                                        <a href="https://www.instagram.com/corporacionkmg/" target="_blank" rel="noopener noreferrer">
                                            <i class="icon-instagram" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                        </a>
                                    </IconButton>
                                </Grid>
                                <Grid>
                                    <IconButton style={{ marginLeft: 20, marginBottom: 20, width: 45 }} id="link" >
                                        <a href="https://www.linkedin.com/company/corporacion-kmg/" target="_blank" rel="noopener noreferrer">
                                            <i class="icon-linkedin" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                        </a>
                                    </IconButton>
                                </Grid>
                                <Grid>
                                    <IconButton style={{ marginLeft: 20, marginBottom: 20, width: 45 }} id="twitter" >
                                        <a href="https://twitter.com/CorporacionKmg/" target="_blank" rel="noopener noreferrer">
                                            <i class="icon-twitter" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                        </a>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Carousel */}
                    <Grid item xs={10} sm={10} md={10}>
                        <Grid container alignContent="center" style={{ marginTop: '30vh' }}>

                            <div id="carousel" className="noselect">

                                <style>{cssstyle}</style>
                                <ReactCSSTransitionGroup
                                    transitionName={this.state.direction}>
                                    {this.generateItems()}


                                </ReactCSSTransitionGroup>
                            </div>

                        </Grid>

                    </Grid>

                    <Grid item xs={1} sm={1} md={1}>
                        {/* <IconButton className={classes.chat}>
                            <i class="icon-chat" style={{ color: "white" }} />
                        </IconButton> */}
                    </Grid>

                </Grid>
            </>
        )
    }
}

const headerServices = withStyles(stylesServices, { withTheme: true })(HeaderServices);
export default headerServices


class Item extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            level: this.props.level
        }
        this.services = this.services.bind(this);
    }

    services(service) {
        switch (service) {
            case 0:
                document.getElementById('admin').scrollIntoView({
                    behavior: 'smooth'
                });
                break;
            case 1:
                document.getElementById('seo').scrollIntoView({
                    behavior: 'smooth'
                });
                break;
            case 2:
                document.getElementById('it').scrollIntoView({
                    behavior: 'smooth'
                });
                break;
            case 3:
                document.getElementById('client').scrollIntoView({
                    behavior: 'smooth'
                });
                break;
            case 4:
                document.getElementById('tecnoInfraestructura').scrollIntoView({
                    behavior: 'smooth'
                });
                break;
            case 5:
                document.getElementById('software').scrollIntoView({
                    behavior: 'smooth'
                });
                break;
            case 6:
                document.getElementById('rpa').scrollIntoView({
                    behavior: 'smooth'
                });
                break;

            default:
                break;
        }
    }

    render() {
        const className = 'item level' + this.props.level
        return (
            <>
                <Grid container className={className} justify='center' onClick={this.services.bind(this, this.props.id.id)} >
                    <i class={this.props.id.img} style={{ fontSize: 150 }} />
                    <Typography style={{ color: '#4884cc' }}>{this.props.id.title}</Typography>
                </Grid>
            </>
        )
    }
}



const cssstyle = `
#carousel {
    position: absolute;
    height: 160px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.item {
    color: white;
    text-align: center;
    font-size: 40px;
    position: absolute;
    transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
}


.level-2 {
    opacity: 0.5;
    height:200px;
    width: 180px;
    line-height:200px;
    left: 85%;
    margin-top: -40px;
}

.level-1 {
    opacity: 0.5;
    height: 200px;
    width: 180px;
    line-height: 200px;
    left: 65%;
    margin-top: -20px;
}

.level0 {
    color: white;
    height: 200px;
    width: 180px;
    line-height: 200px;
    left: 45%;    
    margin-top: 10px;
    -ms-transform: scale(1.3);
    transform: scale(1.3);   
}

.level1 {
    opacity: 0.5;
    height: 200px;
    width: 180px;
    line-height: 200px;
    margin-top: -20px;
    left: 25%;
}

.level2 {
    opacity: 0.5;
    height: 200px;
    width: 180px;
    line-height: 200px;
    margin-top: -40px;
    left: 7%;
}

@media only screen 
and (max-width: 500px)
{
    .right-enter.right-enter-active {
        opacity: 1;
        margin-top: 25px;
        transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
    }

    .level0 {
        left: 30%;
        margin-top: -20px   
    }
  
}

.right-leave.right-leave-active {
    opacity: 0;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.noselect {
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none;  
            user-select: none;
}
`