export const styles = (theme) => ({
    paddingContainer: {
        [theme.breakpoints.up('md')]: {
            padding: 100,


        },
    },
    typografyData: {
        color: "#030a52",
        fontSize: 35,
     
        marginTop: "10vh",
        [theme.breakpoints.down('sm')]: {
       
        },
        [theme.breakpoints.up('md')]: {
            padding: 20,
        },
    }
})