import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RightMenu from '../../Home/RightMenu';

// Assets
import burbuja3 from './assets/burbuja3.png';
import burbuja1 from './assets/burbuja1.png';
import { styles } from "./constants";

/*Animations */
import Fade from 'react-reveal/Fade';

class Message extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            show: false,
            TextDinamic: 'ingeniosas',
            showDialogMessage: true,
        })
        this.handleScroll = this.handleScroll.bind(this);

    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        setInterval(this.changePrincipalTitle.bind(this), 3000);
        this.animationBackground();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var y = window.scrollY;
        if (y > 25) {
            this.setState({
                show: true,
                showDialogMessage: false
            })
        } else {
            this.setState({
                show: false,
                showDialogMessage: true
            })
        }
        if (y > 600 && y < 6746) {
            document.getElementById("floatMenu").style.color = "black"
        } else {
            document.getElementById("floatMenu").style.color = "white"
        }
    }

    changePrincipalTitle = () => {
        let arr = ['ingeniosas', 'novedosas', 'grandes']
        let index;
        arr.forEach((element, i) => {
            if (this.state.TextDinamic === element) {
                if (arr.indexOf(element) === 2) {
                    index = -1;
                } else {
                    index = i;
                }
            }
        });
        this.setState({ TextDinamic: arr[index + 1] });
    }

    animationBackground = () => {
        let styleSheet = document.styleSheets[0];

        let animationBurbble1 = `animation`;
        let keyframes =
            `@-webkit-keyframes ${animationBurbble1} {
            0% {-webkit-transform: scale(1) translateY(0); opacity: 0.3} 
            100% {-webkit-transform: scale(3) translateY(-50%) translateX(-20%); opacity: 1} 
            }`;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
        this.setState({ animationBurbble1: animationBurbble1 });

        let animationBurbble2 = `animation2`;
        keyframes =
            `@-webkit-keyframes ${animationBurbble2} {
            0% {-webkit-transform: scale(1) translateY(0); opacity: 0} 
            100% {-webkit-transform: scale(2) translateY(-180%) translateX(-150%); opacity: 1} 
            }`;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
        this.setState({ animationBurbble2: animationBurbble2 });

        let animationBurbble3 = `animation3`;
        keyframes =
            `@-webkit-keyframes ${animationBurbble3} {
            0% {-webkit-transform: scale(1) translateY(0); opacity: 0.3} 
            100% {-webkit-transform: scale(2.2) translateY(70%) translateX(100%); opacity: 1} 
            }`;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
        this.setState({ animationBurbble3: animationBurbble3 });

        let animationBurbble4 = `animation4`;
        keyframes =
            `@-webkit-keyframes ${animationBurbble4} {
            0% {-webkit-transform: scale(1) translateY(0); opacity: 0} 
            100% {-webkit-transform: scale(2.5) translateY(-80%) translateX(-60%); opacity: 1} 
            }`;
        styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
        this.setState({ animationBurbble4: animationBurbble4 });
    }

    handleLightDark = () => {
        // document.body.style.backgroundColor = "#101f77";
    }

    render() {
        const { classes } = this.props;

        return (
            <>

                {/* Bloque lateral izquierdo */}
                < Grid item xs={4} sm={5} md={5} style={{ zIndex: '2' }
                } >
                    <Grid container alignContent="center">
                        <Grid item xs={2} className={classes.sideMenu}>
                            <Fade left cascade>
                                <Grid>
                                    <IconButton className={classes.sideMenuOption}>
                                        <i class="icon-whatsapp" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                    </IconButton>
                                </Grid>
                                <Grid>
                                    <IconButton className={classes.sideMenuOption}>
                                        <i class="icon-instagram" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                    </IconButton>
                                </Grid>
                                <Grid>
                                    <IconButton className={classes.sideMenuOption}>
                                        <i class="icon-linkedin" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                    </IconButton>
                                </Grid>
                                <Grid>
                                    <IconButton className={classes.sideMenuOption}>
                                        <i class="icon-twitter" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                    </IconButton>
                                </Grid>
                            </Fade>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={7}>
                            <Fade bottom cascade>
                                <Typography variant="h1" className={classes.principalTitle}>
                                    <strong>Un equipo apasionado impulsado por </strong>
                                    <strong className={classes.titleDecoration}>{this.state.TextDinamic}</strong>
                                    <strong> soluciones digitales</strong>
                                </Typography>
                            </Fade>
                            <Typography variant="h5" className={classes.secondTitle}>
                                <div style={{ color: '#4884cc' }}>&#8213;&#160;</div><Fade bottom cascade>Nuestro Equipo</Fade>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid >

                {/* Bloque lateral derecho */}
                <Grid item xs={12} sm={12} md={12} className={classes.backgroundPrincipal}>
                    <Grid container>

                        <Grid item xs={11} style={{ zIndex: '1' }}>
                            {/* Globo-1 */}
                            <div
                                className={classes.burbble1}
                                style={{
                                    backgroundImage: 'url(' + burbuja3 + ')',
                                    animationName: this.state.animationBurbble1,
                                    animationTimingFunction: 'ease-in-out',
                                    animationDuration: '6s',
                                    animationDelay: '0.5s',
                                    animationIterationCount: "infinite",
                                    animationDirection: 'normal',
                                    animationFillMode: 'forwards',
                                }}
                            />

                            {/* Globo-2 */}
                            <div
                                className={classes.burbble2}
                                style={{
                                    backgroundImage: 'url(' + burbuja3 + ')',
                                    animationName: this.state.animationBurbble2,
                                    animationTimingFunction: 'ease-in-out',
                                    animationDuration: '6s',
                                    animationDelay: '0s',
                                    animationIterationCount: "infinite",
                                    animationDirection: 'normal',
                                    animationFillMode: 'forwards',
                                }}
                            />

                            {/* Globo-3 */}
                            <div
                                className={classes.burbble3}
                                style={{
                                    backgroundImage: 'url(' + burbuja1 + ')',
                                    animationName: this.state.animationBurbble3,
                                    animationTimingFunction: 'ease-in-out',
                                    animationDuration: '5s',
                                    animationDelay: '0s',
                                    animationIterationCount: "infinite",
                                    animationDirection: 'normal',
                                    animationFillMode: 'forwards',
                                }}
                            />

                            {/* Globo-4 */}
                            <div
                                className={classes.burbble4}
                                style={{
                                    backgroundImage: 'url(' + burbuja3 + ')',
                                    animationName: this.state.animationBurbble4,
                                    animationTimingFunction: 'ease-in-out',
                                    animationDuration: '5s',
                                    animationDelay: '0.5s',
                                    animationIterationCount: "infinite",
                                    animationDirection: 'normal',
                                    animationFillMode: 'forwards',
                                }}
                            />

                        </Grid>
                        <Grid item xs={1} style={{ zIndex: '10' }}>
                            <RightMenu />
                        </Grid>
                    </Grid>
                </Grid >
            </>
        );
    }
}

const message = withStyles(styles, { withTheme: true })(Message);

export default message;