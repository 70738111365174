import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, withStyles } from '@material-ui/core';

/* Assets */
import imagenCentral from './assets/imagenCentral.jpg';

import { styles } from "./constants";

/*Animations */
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
function mapStateToProps(state) {
    return {

    };
}



class Info extends Component {
    render() {
        const { classes } = this.props;

        return (
            <>

                <Fade bottom>
                    <Grid container direction="row" className={classes.principalText}>
                        <Grid item xs={12}>
                            <Typography variant="h2" className={classes.title}>
                                <Fade bottom cascade>
                                    <strong>EXPERIENCIA TECNOLÓGICA</strong>
                                </Fade>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.generalTextStyle}>
                            <Typography variant="h6">
                                <Fade bottom cascade>
                                    Innova más allá de la idea que has puesto en marcha!
                                </Fade>
                                <br />
                                <br />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.generalTextStyle}>
                            <Typography variant="h5">
                                Contamos con un equipo de profesionales especialistas en cada una de las etapas
                                del ciclo de vida de las plataformas tecnológicas, que abarcan desde la consultoría
                                en la infraestructura tecnológica; concepción, diseño y desarrollo de una nueva
                                aplicación hasta el soporte en la continuidad operativa de plataformas productivas.
                                Nuestro fin es crear una experiencia excepcional y entregar resultados a medida
                                para todas las necesidades de la audiencia.
                        </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.generalTextStyle}>
                            <Typography variant="h5" >
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Zoom top>
                                <img src={imagenCentral} width="100%" alt="logo completo KMG" />
                            </Zoom>
                        </Grid>

                        <Grid container direction="row" >
                            <Fade left>
                                <Grid item xs={12} sm={12} lg={6} style={{ zIndex: 99999 }}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.squareText1}>
                                        <div style={{ color: 'white', textAlign: 'left', marginLeft: 3 }}>
                                            <Typography variant="h5" >
                                                <strong >Tecnología de punta y</strong>
                                                <br />
                                                <strong style={{ color: '#4884cc' }}>soluciones</strong> <strong >adecuadas</strong>
                                            </Typography>
                                            <br />
                                            <Typography variant="h8">
                                                Desarrollamos para hacer los procesos mas amigables y dar a nuestros
                                                clientes la satisfacción de tener en su empresa un servicio de calidad
                                        </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Fade>
                            <Fade right>
                                <Grid item xs={12} sm={12} lg={6} style={{ zIndex: 99999 }}>
                                    <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.squareText2}>
                                        <div style={{ color: 'white', textAlign: 'right', marginRight: '5%' }}>
                                            <Typography variant="h5" >
                                                <strong style={{ color: '#4884cc' }}>Poder</strong> <strong>para tu empresa</strong>
                                            </Typography>
                                            <br />
                                            <Typography variant="h8">
                                                Construimos las bases tecnológicas para que nuestros
                                        <br />
                                        clientes crezcan enfocados en sus áreas de trabajo
                                        </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Fade>
                        </Grid>

                    </Grid>
                </Fade>
            </>
        );
    }
}

const info = withStyles(styles, { withTheme: true })(Info);

export default connect(
    mapStateToProps,
)(info);