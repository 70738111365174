import { combineReducers } from 'redux';
import { contactReducer } from './_reducerContacto';
import { modalReducer } from './_reducerModal';
import { errorControl} from './_errorControl';
import { loader} from './_reducerLogin';
import { resetAll} from './_resertAll';
import { RESET_ALL} from '../constants';


const appReducer = combineReducers({
    contactReducer,
    modalReducer, 
    errorControl,
    loader,
    resetAll,

});

const resetState = combineReducers({
    contactReducer,
    modalReducer,
    errorControl,
    loader,
    resetAll,
});



const rootReducer = (state, action) => {
    if (action.type === RESET_ALL) {
        state = undefined
        return resetState(state, action)
    } else {
        return appReducer(state, action)
    }
}

export default rootReducer;