import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { connect } from "react-redux";
import { styles } from './constants';
import mundo from './assets/img/globo.png';

/*ACTIONS*/
import { openContact } from '../../../Redux/Actions/_actionContacto';

/*COMPONENTES*/
import RightMenu from '../RightMenu';

/*Animations*/
import Fade from 'react-reveal/Fade';



class cover extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            show: false,
            color: "white"
        })
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var y = window.scrollY;
        // alert(y)
        if (y > 25) {
            this.setState({
                show: true
            })
        } else {
            this.setState({
                show: false
            })
        }

        // if (y >= 1000 && y < 2000) {
        //     document.getElementById("floatMenu").style.color = "white"

        // }

        var color = "";
        if (y > 500 && y < 3000) {
            color = "black";
        } else if (y > 4000 && y < 5500) {
            color = "black";
        } else {
            color = "white";
        }

        this.setState(
            {
                color: color
            }
        )







    };

    handleLightDark = () => {
        // document.body.style.backgroundColor = "#101f77";
    };

    handleContact = () => {
        this.props.openContact(true);
    };

    render() {
        const { classes } = this.props;
        return (
            <>
                {/* Bloque lateral izquierdo */}
                <Grid item xs={12} sm={5} md={5} className={classes.leftPanel}>
                    <Grid container alignContent="center">

                        <Grid item xs={0} style={{ marginTop: "55vh", position: "fixed" }}>
                            <Grid>
                                <Fade left cascade>
                                    <IconButton className={classes.icons}>
                                        <a href="https://api.whatsapp.com/send?phone=573004736726" target="_blank" rel="noopener noreferrer">
                                            <i class="icon-whatsapp" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                        </a>
                                    </IconButton>
                                </Fade>
                            </Grid>
                            <Grid>
                                <Fade left cascade>
                                    <IconButton className={classes.icons}>
                                        <a href="https://www.instagram.com/corporacionkmg/" target="_blank" rel="noopener noreferrer">
                                            <i class="icon-instagram" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                        </a>
                                    </IconButton>
                                </Fade>
                            </Grid>
                            <Grid>
                                <Fade left cascade>
                                    <IconButton className={classes.icons}>
                                        <a href="https://www.linkedin.com/company/corporacion-kmg/" target="_blank" rel="noopener noreferrer">
                                            <i class="icon-linkedin" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                        </a>
                                    </IconButton>
                                </Fade>
                            </Grid>
                            <Grid>
                                <Fade left cascade>
                                    <IconButton className={classes.icons}>
                                        <a href="https://twitter.com/CorporacionKmg/" target="_blank" rel="noopener noreferrer">
                                            <i class="icon-twitter" style={{ color: "#aeb0b3", fontSize: 20 }} />
                                        </a>
                                    </IconButton>
                                </Fade>
                            </Grid>

                        </Grid>

                        <Grid item xs={2} md={2}></Grid>
                        <Grid item xs={10} md={10} >
                            <Fade bottom cascade>

                                <Grid container className={classes.marginText}>
                                    <Grid item xs={12} >
                                        <Typography className={classes.typographyCover}> <Fade bottom cascade>Experiencia </Fade></Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography className={classes.typographyCover}> <Fade bottom cascade>tecnológica </Fade></Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: -10 }} >
                                        <Typography className={classes.typography}> <Fade bottom cascade>soluciones que impulsan el </Fade></Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography className={classes.typography}> <Fade bottom cascade>crecimiento de nuestros clientes </Fade></Typography>
                                    </Grid>
                                </Grid>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
                < Grid item xs={12} sm={7} md={7} className={classes.rightPanel} >
                    <Grid container>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={2}  >
                            <RightMenu />
                        </ Grid>
                    </Grid>

                </Grid>
                <Grid item xs={0} sm={5} md={5} style={{ marginTop: "-100vh" }}></Grid>
                <Grid item xs={12} sm={6} md={6} style={{
                    height: "100vh",
                    marginTop: "-90vh"
                }}>

                    <img  alt="mundo kmg" className={classes.floatWorld} src={mundo} style={{
                        objectFit: "cover",
                        width: "100%",
                       
                    }}></img>


                </Grid>


            </>
        );
    }
}
const Cover = withStyles(styles, { withTheme: true })(cover);

function mapStateToProps(state) {
    return {
        contactPayload: state.contactReducer.contactPayload,

    };
}


export default connect(mapStateToProps, {
    openContact,

})(Cover);