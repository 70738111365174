import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Carousel } from 'react-bootstrap';

/* Assets */
import Uno from './assets/Uno.png'
import Ilustracion1 from './assets/ilustracion1.png'
import Dos from './assets/Dos.png'
import Ilustracion2 from './assets/ilustracion2.png'
import Tres from './assets/Tres.png'
import Ilustracion3 from './assets/ilustracion3.png'
import Cuatro from './assets/Cuatro.png'
import Ilustracion4 from './assets/ilustracion4.png'
import Cinco from './assets/Cinco.png'
import Ilustracion5 from './assets/ilustracion5.png'
import UnoGris from './assets/01ilustracion.png'
import DosGris from './assets/02ilustracion.png'
import TresGris from './assets/03ilustracion.png'
import CuatroGris from './assets/04ilustracion.png'
import CincoGris from './assets/05ilustracion.png'

import { styles } from "./constants";

/*Animations */
import Fade from 'react-reveal/Fade';

class Benefits extends Component {

    render() {
        const { classes } = this.props;

        return (
            <>
                <Grid item xs={12} className={classes.root} >
                    <Grid container style={{ marginTop: '8vh' }}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Typography variant="h5" style={{ color: '#999999', display: 'flex' }}>
                                <div style={{ color: '#4884cc' }}>&#8213;&#160;</div><strong><Fade bottom cascade>Nuestros Beneficios</Fade></strong>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: '8vh' }}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginRight: '1%' }}>
                            <Fade left>
                                <div style={{ background: '#999999' }}>
                                    <img src={Uno} style={{ padding: '20% 0% 0% 5%' }} width="30%" alt="logo completo KMG" />
                                    <Typography variant="h3" style={{ color: '#0e0345', padding: '5% 0% 0% 5%' }}>
                                        <strong>Respaldo y garantía</strong>
                                    </Typography>
                                    <Typography variant="h5" style={{ color: 'white', padding: '5% 10% 5% 5%' }}>
                                        Nos aseguramos de que la velocidad, la seguridad y la facilidad de uso del producto sean óptimas.
                                    </Typography>
                                </div>
                            </Fade>
                        </Grid>
                        <Grid item xs={5} sm={5} lg={5} className={classes.ilustracion1}>
                            <Fade right>
                                <img src={Ilustracion1} style={{ padding: '5% 0% 10% 0%' }} width="100%" alt="logo completo KMG" />
                            </Fade>
                        </Grid>
                    </Grid>


                    <Grid container >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} sm={5} lg={5} className={classes.ilustracion2}>
                            <Fade right>
                                <img src={Ilustracion2} style={{ padding: '5% 0% 10% 0%' }} width="100%" alt="logo completo KMG" />
                            </Fade>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginLeft: '1%' }}>
                            <Fade left>
                                <div style={{ background: '#4884cc' }}>
                                    <img src={Dos} style={{ padding: '20% 0% 0% 5%' }} width="30%" alt="logo completo KMG" />
                                    <Typography variant="h3" style={{ color: '#0e0345', padding: '5% 5% 0% 5%' }}>
                                        <strong>Tecnología vanguardista</strong>
                                    </Typography>
                                    <Typography variant="h5" style={{ color: 'white', padding: '1em '}}>
                                        Aportamos valor e innovación para establecer metas y alcanzar objetivos construyendo productos digitales significativos.
                                    </Typography>
                                </div>
                            </Fade>
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginRight: '1%' }}>
                            <Fade left>
                                <div style={{ background: '#999999' }}>
                                    <img src={Tres} style={{ padding: '20% 0% 0% 5%' }} width="30%" alt="logo completo KMG" />
                                    <Typography variant="h3" style={{ color: '#0e0345', padding: '5% 0% 0% 5%', }}>
                                        <strong>Mejor experiencia de usuarios</strong>
                                    </Typography>
                                    <Typography variant="h5" style={{ color: 'white', padding: '5% 10% 5% 5%', }}>
                                        Trabajamos en equipo examinando cada detalle para mejorar la experiencia del cliente aumentando su excelencia operativa.
                                    </Typography>
                                </div>
                            </Fade>
                        </Grid>
                        <Grid item xs={5} sm={5} lg={5} className={classes.ilustracion3}>
                            <Fade right>
                                <img src={Ilustracion3} style={{ padding: '5% 0% 10% 0%' }} width="100%" alt="logo completo KMG" />
                            </Fade>
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} sm={5} lg={5} className={classes.ilustracion4}>
                            <Fade right>
                                <img src={Ilustracion4} style={{ padding: '10% 0% 10% 0%' }} width="100%" alt="logo completo KMG" />
                            </Fade>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginLeft: '1%' }}>
                            <Fade left>
                                <div style={{ background: '#4884cc' }}>
                                    <img src={Cuatro} style={{ padding: '20% 0% 0% 5%' }} width="30%" alt="logo completo KMG" />
                                    <Typography variant="h3" style={{ color: '#0e0345', padding: '5% 5% 0% 5%', }}>
                                        <strong>Procesos más eficientes</strong>
                                    </Typography>
                                    <Typography variant="h5" style={{ color: 'white', padding: '5% 10% 5% 5%', }}>
                                        Nos enfocamos en la planificación estratégica con sistemas personalizados y los equipos adecuados para crear procesos eficientes.
                                    </Typography>
                                </div>
                            </Fade>
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginRight: '1%' }}>
                            <Fade left>
                                <div style={{ background: '#999999' }}>
                                    <img src={Cinco} style={{ padding: '20% 0% 0% 5%' }} width="30%" alt="logo completo KMG" />
                                    <Typography variant="h3" style={{ color: '#0e0345', padding: '5% 0% 0% 5%', }}>
                                        <strong>Aumento de la productividad</strong>
                                    </Typography>
                                    <Typography variant="h5" style={{ color: 'white', padding: '5% 10% 5% 5%', }}>
                                        Adaptamos nuestras metodologías y estilos de comunicación con procesos mas rápidos y controlados para potenciar su negocio.
                                    </Typography>
                                </div>
                            </Fade>
                        </Grid>
                        <Grid item xs={5} sm={5} lg={5} className={classes.ilustracion5}>
                            <Fade right>
                                <img src={Ilustracion5} width="100%" alt="logo completo KMG" />
                            </Fade>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={12} className={classes.rootMobile} >
                    <Grid container style={{ marginTop: '8vh' }}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Typography variant="h5" style={{ color: '#999999', display: 'flex', }}>
                                <div style={{ color: '#4884cc' }}>&#8213;&#160;</div><strong><Fade bottom cascade>Nuestros Beneficios</Fade></strong>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: '8vh' }}>

                        <Fade bottom>
                            <Grid container direction="row">
                                <Grid item xs={12} style={{ height: '60vh' }}>
                                    <Carousel>
                                        <Carousel.Item interval={3000}>
                                            <Grid item xs={12} sm={12} lg={5} className={classes.carouselItem}>
                                                <div
                                                    style={{
                                                        backgroundImage: 'url(' + UnoGris + ')',
                                                        backgroundSize: '40% 40%',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: 'center 30%',

                                                    }}
                                                >
                                                    <Typography variant="h4" style={{ color: '#0e0345', padding: '30% 0% 0% 5%', }}>
                                                        <center>
                                                            <strong>Respaldo y garantía</strong>
                                                        </center>
                                                    </Typography>
                                                    <Typography variant="h6" style={{ color: 'white', padding: '5% 15% 5% 15%', }}>
                                                        <center>
                                                            Nos aseguramos de que la velocidad, la seguridad y la facilidad de uso del producto sean óptimas.
                                                    </center>
                                                    </Typography>
                                                </div>
                                                <center>
                                                    <img style={{ paddingBottom: '10%' }} src={Ilustracion1} width="60%" alt="logo completo KMG" />
                                                </center>
                                            </Grid>
                                        </Carousel.Item>
                                        <Carousel.Item interval={3000}>
                                            <Grid item xs={12} sm={12} lg={5} className={classes.carouselItem}>
                                                <div
                                                    style={{
                                                        backgroundImage: 'url(' + DosGris + ')',
                                                        backgroundSize: '40% 40%',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: 'center 30%',

                                                    }}
                                                >
                                                    <Typography variant="h4" style={{ color: '#0e0345', padding: '30% 0% 0% 5%', }}>
                                                        <center>
                                                            <strong>Respaldo y garantía</strong>
                                                        </center>
                                                    </Typography>
                                                    <Typography variant="h6" style={{ color: 'white', padding: '5% 15% 5% 15%', }}>
                                                        <center>
                                                            Nos aseguramos de que la velocidad, la seguridad y la facilidad de uso del producto sean óptimas.
                                                    </center>
                                                    </Typography>
                                                </div>
                                                <center>
                                                    <img style={{ paddingBottom: '10%' }} src={Ilustracion2} width="60%" alt="logo completo KMG" />
                                                </center>
                                            </Grid>
                                        </Carousel.Item>
                                        <Carousel.Item interval={3000}>
                                            <Grid item xs={12} sm={12} lg={5} className={classes.carouselItem}>
                                                <div
                                                    style={{
                                                        backgroundImage: 'url(' + TresGris + ')',
                                                        backgroundSize: '40% 40%',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: 'center 30%',

                                                    }}
                                                >
                                                    <Typography variant="h4" style={{ color: '#0e0345', padding: '30% 0% 0% 5%', }}>
                                                        <center>
                                                            <strong>Mejor experiencia de usuarios</strong>
                                                        </center>
                                                    </Typography>
                                                    <Typography variant="h6" style={{ color: 'white', padding: '5% 15% 5% 15%', }}>
                                                        <center>
                                                            Trabajamos en equipo examinando cada detalle para mejorar la experiencia del cliente aumentando su excelencia operativa.
                                                    </center>
                                                    </Typography>
                                                </div>
                                                <center>
                                                    <img style={{ paddingBottom: '10%' }} src={Ilustracion3} width="60%" alt="logo completo KMG" />
                                                </center>
                                            </Grid>
                                        </Carousel.Item>
                                        <Carousel.Item interval={3000}>
                                            <Grid item xs={12} sm={12} lg={5} className={classes.carouselItem}>
                                                <div
                                                    style={{
                                                        backgroundImage: 'url(' + CuatroGris + ')',
                                                        backgroundSize: '40% 40%',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: 'center 30%',

                                                    }}
                                                >
                                                    <Typography variant="h4" style={{ color: '#0e0345', padding: '30% 0% 0% 5%', }}>
                                                        <center>
                                                            <strong>Procesos más eficientes</strong>
                                                        </center>
                                                    </Typography>
                                                    <Typography variant="h6" style={{ color: 'white', padding: '5% 15% 5% 15%', }}>
                                                        <center>
                                                            Nos enfocamos en la planificación estratégica con sistemas personalizados y los equipos adecuados para crear procesos eficientes.
                                                    </center>
                                                    </Typography>
                                                </div>
                                                <center>
                                                    <img style={{ paddingBottom: '10%' }} src={Ilustracion4} width="60%" alt="logo completo KMG" />
                                                </center>
                                            </Grid>
                                        </Carousel.Item>
                                        <Carousel.Item interval={3000}>
                                            <Grid item xs={12} sm={12} lg={5} className={classes.carouselItem}>
                                                <div
                                                    style={{
                                                        backgroundImage: 'url(' + CincoGris + ')',
                                                        backgroundSize: '40% 40%',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: 'center 30%',
                                                    }}
                                                >
                                                    <Typography variant="h4" style={{ color: '#0e0345', padding: '30% 0% 0% 5%', }}>
                                                        <center>
                                                            <strong>Aumento de la productividad</strong>
                                                        </center>
                                                    </Typography>
                                                    <Typography variant="h6" style={{ color: 'white', padding: '5% 15% 5% 15%', }}>
                                                        <center>
                                                            Adaptamos nuestras metodologías y estilos de comunicación con procesos mas rápidos y controlados para potenciar su negocio.
                                                    </center>
                                                    </Typography>
                                                </div>
                                                <center>
                                                    <img style={{ paddingBottom: '10%' }} src={Ilustracion5} width="60%" alt="logo completo KMG" />
                                                </center>
                                            </Grid>
                                        </Carousel.Item>
                                    </Carousel>
                                </Grid>

                            </Grid>

                        </Fade>
                    </Grid>

                </Grid>
            </>
        );
    }
}

const benefits = withStyles(styles, { withTheme: true })(Benefits);

export default benefits;