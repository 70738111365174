import { SET_MENU } from "../constants";


export const modalReducer = (state = {}, action) => {
    switch (action.type) {

        case SET_MENU:
            return {
                ...state,
                getMenu: action.payload
            };
        default:
            return state;
    }
}
