export const styles = (theme) => ({
    container: {
        [theme.breakpoints.up('md')]: {
            marginTop: '5%'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '40%'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '35%'
        },
    },

});
