import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';

import project1 from './assets/img/portafolios.jpg';
import project2 from './assets/img/portafolios2.jpg';


/*Animatins*/
import Fade from 'react-reveal/Fade';

class Projects extends Component {
    render() {
        return (

            <Grid container direction="row" alignItems="center" style={{ marginTop: "10vh", marginBottom: "10vh", }}>
                <Grid item xs={12} style={{ margin: 50 }}>
                    <Fade bottom>

                        <Grid container >
                            <Grid item xs={12} style={{ textAlign: "center", color: "#0a2a4a" }}>
                                <Typography variant="h3" >
                                    Proyectos actuales
                            </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography variant="h5">
                                    Desde proyectos cortos, claramente definidos y temporales hasta colaboraciones a largo
                            </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Typography variant="h5">
                                    plazo con clientes para los que seguimos colaborando durante años
                            </Typography>
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>



                <Grid item xs={12} style={{ marginTop: "20vh", marginBottom: "10vh" }}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Fade left>
                                <img alt="proyectos" src={project1} style={{ width: "100%", objectFit: "cover", boxShadow: "#867a7a 13px 0px 13px -6px" }}></img>
                            </Fade>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Fade right>
                                <img alt="proyectos" src={project2} style={{ width: "100%", objectFit: "cover", marginTop: 50, zIndex: "-5", height: 400 }}></img>
                            </Fade>
                        </Grid>
                        <Grid item xs={0} md={6}>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Fade bottom>
                                <Typography variant="h3" style={{ marginLeft: "20%" }}>¡Mundo KMG!</Typography>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid >
        );
    }
}

export default Projects;