import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';

import { styles } from "./constants";

/* Assets */
import ITBC from './assets/itbc.svg'
import CODE from './assets/code.svg'
import TECBOUND from './assets/tecbound.svg'
import puntosFondo from './assets/puntosFondo.png'

/*Animations */
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

class Allies extends Component {
    render() {
        const { classes } = this.props;

        return (
            <>
                <Grid item xs={12}
                    style={{
                        backgroundImage: 'url(' + puntosFondo + ')',
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        // backgroundPosition: "fixed",
                        margin: '5% 5% 5% 5%',
                        zIndex: '2'
                    }}
                >

                    <Grid style={{ marginBottom: '30vh' }}>
                        <Grid container >
                            <Grid item xs={1}></Grid>
                            <Grid item xs={11}>
                                <Typography variant="h5" className={classes.title}>
                                    <div style={{ color: '#4884cc' }}>&#8213;&#160;</div><strong><Fade bottom cascade>Aliados Comerciales</Fade></strong>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" style={{ marginTop: '10%', alignItems: 'center' }}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <Grid item xs={12} className={classes.titlePrincipal}>
                                    <Fade bottom>

                                        <Typography variant="h2" className={classes.titleSize}>
                                            <strong>¡Marcamos la diferencia!</strong>
                                            <br />
                                            <strong>Y nuestros aliados lo confirman</strong>
                                        </Typography>
                                    </Fade>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid container direction="row" justify="space-around" alignItems="center" style={{ margin: '10% 0% 0% 0%' }}>
                            <Grid item className={classes.logosStyle}>
                                <Zoom left>
                                    <img
                                        className="d-block w-100"
                                        alt=""
                                        src={ITBC}
                                    />
                                </Zoom>
                            </Grid>
                            <Grid item className={classes.logosStyle}>
                                <Zoom left>
                                    <img
                                        className="d-block w-100"
                                        alt=""
                                        src={CODE}
                                    />
                                </Zoom>
                            </Grid>
                            <Grid item className={classes.logosStyle}>
                                <Zoom left>
                                    <img
                                        className="d-block w-100"
                                        alt=""
                                        src={TECBOUND}
                                    />
                                </Zoom>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>


            </>
        );
    }
}

const allies = withStyles(styles, { withTheme: true })(Allies);

export default allies;