import { ERROR_CONTROL} from "../constants";

export const errorControl = (state = {}, action) =>{
    switch(action.type){
        case ERROR_CONTROL: 
        return{ ...state,
            errorControlPayload: action.payload,
            errorControlMessage: action.message,
            errorControlAlertColor: action.alertColor,
        
        };
        default: 
        return state;
    }
}