import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import footerImg from './assets/img/footer.jpg';
import { styles } from './constants';

import Fade from 'react-reveal/Fade';

class footer extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container style={{

                backgroundImage: 'url(' + footerImg + ')',
                width: "100%",
                backgroundSize: "cover",
                repeat: 'no-repeat',
                height: "100vh"
            }}>
                <Grid item xs={0} md={2}></Grid>
                <Grid item xs={12} md={10} className={classes.content}>

                    <Typography className={classes.title} ><Fade left cascade>¡Conéctate a una</Fade></Typography>
                    <Typography className={classes.title}><Fade right cascade>experiencia digital!</Fade></Typography>
                    <Typography className={classes.email} style={{ textDecoration: "underline" }}><Fade bottom cascade> corporacion@kmg.com.ve</Fade></Typography>
                </Grid>
                <Grid item xs={12} style={{ color: "white", textAlign: "center", margin: "auto" }} >
                    <Typography >© 2021 - Corporación KMG, c.a. | </Typography>  <Link style={{ textDecoration: 'none', color: "white", }} to={'/PoliticasPrivacidad'}>Políticas de privacidad</Link>
                </Grid>

            </Grid>
        );
    }
}
const Footer = withStyles(styles, { withTheme: true })(footer);
export default Footer;