
export const styles = (theme) => ({
    logo:{
     
        [theme.breakpoints.down('sm')]: {
            width: "200px",
        },
        [theme.breakpoints.up('md')]: {
            width: "300px",
        },
    },
    title:{
        [theme.breakpoints.down('sm')]: {
            fontSize: "35px"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "55px"
        },
    },
    text:{
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "25px"
        },
    }


});