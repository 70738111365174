import fondo from './TechnologicalInfrastructure/assets/img/fondo1.jpg';

export const stylesServices = (theme) => ({

    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "35px"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "55px"
        },
        [theme.breakpoints.down('xs')]: {
            padding: 25,
        },
    },

    text: {
        [theme.breakpoints.down('xs')]: {
            padding: 25
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",

        },
        [theme.breakpoints.up('md')]: {
            fontSize: "25px"
        },
    },

    /*---ESTILOS COMPONENTE HEADER--- */
    fondoHeader: {
        backgroundColor: "#371888",
        height: "100vh",
    },
    menuData: {
        textTransform: 'none',
        color: "white",

        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
        [theme.breakpoints.up('md')]: {

        },
        [theme.breakpoints.up('lg')]: {

        },
    },

    redes: {
        top: "55vh",
        position: "fixed",
        [theme.breakpoints.down('xs')]: {
            top: "50vh"
        },
    },

    chat: {
        marginTop: "85vh",
        width: 50,
        height: 50,
        marginLeft: 40,
        marginBottom: 10,
        backgroundColor: "#11a3e6",
        position: 'fixed',
        boxShadow: '1px 2px 2px',

        [theme.breakpoints.down('sm')]: {
            marginLeft: -30,
            marginTop: "75vh",
        },
    },

    /*---ESTILOS COMPONENTE IT ---*/
    menuRight: {
        top: "0",
        color: "white",
        position: "fixed",
        right: 120,


        [theme.breakpoints.down('xs')]: {
            right: -5,
            padding: 0
        },
    },

    TypographyIT: {
        marginTop: '-30vh',
        [theme.breakpoints.down('xs')]: {
            marginTop: '-5vh',
        },
    },

    TypographySubtitle: {
        marginTop: '-8vh',
        color: '#5D677F',
        textAlign: 'justify',
        [theme.breakpoints.down('xs')]: {
            marginTop: '-5vh',
        },
    },

    /*---ESTILOS COMPONENTE SOFTWARE--- */
    imgSoftware: {
        width: '100%',
        objectFit: 'cover'
    },

    iconoSoftware: {
        marginTop: '10vh',
        marginBottom: '3vh',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },

    titleSoftware: {
        marginLeft: '-20vh',
        marginTop: '-5vh',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0vh',
            marginTop: '-0vh',
            padding: '0px',
            textAlign: 'left',
        },
        zIndex: 1
    },

    TypographySoftware: {
        marginTop: '0vh',
        marginLeft: '-20vh',
        color: '#5D677F',
        textAlign: 'left',

        [theme.breakpoints.down('sm')]: {
            marginLeft: '0vh',
            textAlign: 'left',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '-5vh',
            marginLeft: '0vh',
            textAlign: 'justify',
        },
    },

    /*---ESTILOS COMPONENTE TECHNOLOGICALINFRAESTRUCTURE--- */
    fondoTecno: {
        backgroundImage: 'url(' + fondo + ')',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
    },

    iconoTecno: {
        marginTop:'-25vh',
        [theme.breakpoints.down('xs')]: {
            width: 110,
            display: 'none'
        },
    },
    TypographyTitle1: {
        color: 'white',
        textAlign:'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: "35px",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "55px"

        },
    },

    TypographyTitle2: {
        color: 'white',
        marginTop: -20,
        textAlign:'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: -10,
            fontSize: "35px",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "55px"

        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0vh',
            marginTop: -10,
            textAlign: 'center',
        },
    },
    texto: {
        marginTop: '0vh',
        color: 'white',
        textAlign: 'justify',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'justify',
        },
    },

    imgTecno: {
        objectFit: 'cover',
        width: '100%',
        marginTop: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 10,
        },
    },

    /*---   ESTILOS DEL COMPONENTE DELEGATEDADMINISTRATION--- */
    fondoAdmin: {
        marginTop: '10vh',
        objectFit: 'cover',
        width: '100%'
    },

    titleAdmin: {
        marginTop: '-30vh',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'right',
            marginBottom: '-5vh'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '-10vh',
            textAlign: 'right'
        },
    },

    // titleAdmin1: {
    //     color: 'white',
    //     marginTop: '-20vh',
    //     [theme.breakpoints.down('sm')]: {
    //         marginTop: '-5vh',
    //         fontSize: "35px",
    //     },
    //     [theme.breakpoints.up('md')]: {
    //         fontSize: "55px"

    //     },
    //     [theme.breakpoints.down('xs')]: {
    //         marginTop: '0vh',
    //         textAlign: 'right'
    //     },
    // },

    marginLetra: {
        marginTop: '-15px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '-0px',
            textAlign: 'right',
        },
    },

    TypographyAdmin: {
        marginTop: '-3vh',

        [theme.breakpoints.down('xs')]: {
            marginTop: '-0vh',
            textAlign: 'left',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '-0px',
            textAlign: 'left',
        },
    },

    /*--ESTILOS DEL COMPONENTE RPA */
    iconoRPA: {
        marginTop: '25vh',
        marginBottom: '8vh',

        [theme.breakpoints.down('sm')]: {
            marginTop: '10vh',
            marginBottom: '0vh',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },

    title2: {
        marginTop: -20,
        [theme.breakpoints.down('sm')]: {
            fontSize: "35px",
            marginTop: 0,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "55px"
        },
    },

    /*---ESTILOS DEL COMPONENTE CUSTOMEREXPERIENCE--- */
    iconoCustomer: {
        marginTop: '3vh',
        marginBottom: '5vh',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },

    TypographyCustomer: {
        color: '#5D677F',
        textAlign: 'justify',
        padding: '0px 20px 0px 20px',
        zIndex: 1,

        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginTop: '-5vh'
        },
    },

    customer: {
        [theme.breakpoints.down('sm')]: {
            padding: 10,
            width: 400,
            marginLeft: 0,
            marginTop: -20,
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: 60,
            position: "absolute",
            width: 700,
            marginLeft: '20vh',
            marginTop: 20,
        }
    },

    contenedor: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '30vh',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '0vh',
        },
    },

    move: {
        marginTop: '10vh',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: '5vh',
        },
    },
    TypographyMove: {
        color: '#4D4D4D',
        fontWeight: 'bold',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            fontSize: "35px",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "55px"

        },
    },
    // container:{
    //     height:'50vh'
    // }



});