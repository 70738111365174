import React, { Component } from 'react';
import { connect } from "react-redux";
import { Dialog, List, ListItemText, Grid, ListItem, Button } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import CloseIcon from "@material-ui/icons/Close";


import { setMenu } from '../../Redux/Actions/_actionModal';
import menuFondo from './img/menu.jpg';
import './index.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Menu extends Component {

    handleClose = () => {
        this.props.setMenu(false);
    }

    render() {
        return (
            <Dialog
                style={{ margin: 0, padding: 0, backgroundColor: "transparent" }}
                open={this.props.getMenu}
                TransitionComponent={Transition}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="xs"
                fullWidth={true}

            >
                <div style={{ backgroundImage: "url(" + menuFondo + ")", height: "100vh", width: "100%", objectFit: "cover" }}>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Button onClick={this.handleClose} style={{
                                        color: "white",
                                        padding: 0,
                                        float: "right"

                                    }}>
                                        <CloseIcon

                                        />
                                    </Button>

                                </Grid>
                                <Grid item xs={12}>
                                    <List style={{ color: "white", textAlign: "center", marginTop: "15vh", }}>
                                        <ListItem button style={{ textAlign: "center" }}>
                                            <ListItemText primary="Inicio" />
                                        </ListItem>
                                        <ListItem button style={{ textAlign: "center" }}>
                                            <ListItemText primary="Nosotros" />
                                        </ListItem>
                                        <ListItem button style={{ textAlign: "center" }}>
                                            <ListItemText primary="Servicios" />
                                        </ListItem>
                                        <ListItem button style={{ textAlign: "center" }}>
                                            <ListItemText primary="Contacto" />
                                        </ListItem>

                                    </List>
                                </Grid>

                                <Grid item xs={12} style={{ textAlign: "center", marginTop: "25vh" }}>
                                    <i class="icon-logo" style={{ fontSize: 28, color: "white", marginLeft: -120, }} />
                                </Grid>
                            </Grid>



                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog >
        );
    }
}

function mapStateToProps(state) {
    return {
        getMenu: state.modalReducer.getMenu

    };
}


export default connect(mapStateToProps, {
    setMenu
})(Menu);


