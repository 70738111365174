import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Carousel } from 'react-bootstrap';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

/* Assets */
import imagen1 from './assets/imagen1.jpg'
import imagen2 from './assets/imagen2.jpg'
import imagen5 from './assets/imagen5.jpg'

import { styles } from "./constants";

/*Animations */
import Fade from 'react-reveal/Fade';


class Carrousel extends Component {
    render() {
        const { classes } = this.props;

        return (
            <>
                <Grid item xs={12} style={{ background: '#e6e7e7' }} >

                    <Grid container className={classes.container}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Typography variant="h5" style={{ color: '#999999', display: 'flex'}}>
                                <div style={{ color: '#4884cc' }}>&#8213;&#160;</div><strong><Fade bottom cascade>Nuestro Equipo</Fade></strong>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" style={{ marginTop: '5vh', alignItems: 'center' }}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8}>
                            <Fade bottom cascade>
                                <Typography variant="h5" style={{ color: '#999999', margin: 'auto' }}>

                                    <center>
                                        Especialistas que escucharán atentamente tus espectativas, para proporcionar las más eficientes soluciones tecnológicas.
                                    </center>
                                </Typography>
                                <Typography variant="h5" style={{ color: '#999999', margin: 'auto' }}>
                                    <center>
                                        <strong>¿Lo necesitas? ¡Lo desarrollamos!</strong>
                                    </center>
                                </Typography>
                            </Fade>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" style={{ padding: '5% 0% 10% 0%' }}>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={10} style={{ height: '60vh' }}>
                            <Fade bottom >
                                <Carousel>
                                    <Carousel.Item interval={3000}>
                                        <img
                                            className="d-block w-100"
                                            src={imagen1}
                                            alt="First slide"
                                            style={{ height: "70vh", objectFit: 'cover', }}
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item interval={3000}>
                                        <img
                                            className="d-block w-100"
                                            src={imagen2}
                                            alt="Second slide"
                                            style={{ height: "70vh", objectFit: 'cover', }}
                                        />                                  
                    
                                    </Carousel.Item>
                                    <Carousel.Item interval={3000}>
                                        <img
                                            className="d-block w-100"
                                            src={imagen5}
                                            alt="Third slide"
                                            style={{ height: "70vh", objectFit: 'cover', }}
                                        />
                                    </Carousel.Item>
                                </Carousel>
                            </Fade>
                        </Grid>

                    </Grid>
                </Grid>
            </>
        );
    }
}

const carrousel = withStyles(styles, { withTheme: true })(Carrousel);

export default carrousel;