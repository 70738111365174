import React from 'react';
// import Routes from './Routes';
import './App.css';
import { Switch, Route, Router } from "react-router-dom";
import history from './History'
// import Us from "./Components/Us";
import Home from "./Components/Home";
import Service from './Components/Services';
import About from './Components/About';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import PrivacyPolicies from './Components/PrivacyPolicies';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Titillium',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});

function App() {
  return (
    <Router history={history}>
      <Switch>
        <ThemeProvider theme={theme}>
          
          <Route path="/" exact component={Home} isPrivate />
          {/* <Route path="/Nosotros" component={Us} isPrivate /> */}
          <Route path="/Servicios" component={Service} isPrivate />
          <Route path="/Nosotros" component={About} isPrivate />
          <Route path="/PoliticasPrivacidad" component={PrivacyPolicies} isPrivate />
          {/* <Route  component={Home} isPrivate /> */}
          {/* <Route path="/Contact" component={Contacto} isPrivate /> */}
       
    
        </ThemeProvider>
      </Switch>
    </Router>
  );
}

export default App;

