import React, { Component } from 'react';
import { Grid, AppBar, Toolbar, Button, List, ListItemText, ListItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Contacto from '../Home/Contacto';
import Menu from '../Menu';
import { Link } from 'react-router-dom';
import MenuIcon from "@material-ui/icons/Menu";
import Popover from '@material-ui/core/Popover';

/*ACTIONS*/
import { openContact } from '../../Redux/Actions/_actionContacto';
import { setMenu } from '../../Redux/Actions/_actionModal';

import { styles } from '../Home/Cover/constants.js';

export class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
            show: false,
            color: "#00000000"
        }
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var y = window.scrollY;
        // alert(y)
        if (y > 25) {
            this.setState({
                color: "#030a52"
            })
        } else {
            this.setState({
                color: "#00000000"
            })
        }
    };

    handleContact = () => {
        this.props.openContact(true);
    };

    handleMenu = () => {
        this.props.setMenu(true);
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })

    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    handleToTop = () => {
        window.scrollTo(0, 0);
    }



    render() {
        const { classes } = this.props;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <>
                {/* Menú */}
                <AppBar className={classes.appBarPosition} style={{ backgroundColor: this.state.color, boxShadow: "none" }}>
                    <Toolbar >
                        <Grid container >
                            <Grid item xs={1} sm={4} md={7} >
                                <Link to={'/'} className='text-light' style={{ textDecoration: 'none', color: "white", }}>
                                    <i class="icon-logo" style={{ fontSize: 28, color: this.props.logo }} />
                                </Link>
                            </Grid>
                            <Grid item xs={11} sm={8} md={5} className={classes.menuData}>
                                <Grid container >
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button color="inherit" style={{ textTransform: 'none', borderRadius: "50px", }}>
                                            <Link to={'/'} className='text-light' style={{ textDecoration: 'none', color: "white", }}>Inicio </Link>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button color="inherit" style={{ textTransform: 'none', borderRadius: "50px", }}>
                                            <Link to={'/Nosotros'} className='text-light' style={{ textDecoration: 'none', color: "white", }}>Nosotros </Link>
                                        </Button>
                                    </Grid>
                                    {/* <Grid item xs={2}>
                                        <Button color="inherit" style={{ textTransform: 'none', borderRadius: "50px", }}>Clientes</Button>
                                    </Grid> */}
                                    <Grid item xs={2}>
                                        <Button color="inherit" style={{ textTransform: 'none', borderRadius: "50px", }}>
                                            <Link to={'/Servicios'} style={{ textDecoration: 'none', color: "white", }}>Servicios </Link>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button color="inherit" style={{
                                            textTransform: 'none',
                                            border: "1px solid white",
                                            borderRadius: "50px",
                                            padding: "5px 15px 5px 15px"
                                        }}
                                            onClick={this.handleContact}
                                        >
                                            Contacto
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid xs={11} className={classes.menuIcon}>
                                <Grid container>
                                    <Grid item xs={10}>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={this.handleClick}>
                                            <MenuIcon style={{ color: "white" }} />
                                        </Button>
                                    </Grid>

                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={this.state.anchorEl}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        style={{ marginLeft: 40 }}

                                    >
                                        <Grid container style={{ width: 160 }}>
                                            <Grid item xs={12} style={{ justifyContent: "center" }}>
                                                <List >
                                                    <ListItem onClick={this.handleToTop} button style={{ justifyContent: "center" }}>
                                                        <Link to={'/'} style={{ textDecoration: 'none', color: "black" }}>Inicio </Link>
                                                    </ListItem>
                                                    <ListItem onClick={this.handleToTop} button style={{ justifyContent: "center" }}>
                                                        <Link to={'/Nosotros'} style={{ textDecoration: 'none', color: "black" }}>Nosotros</Link>
                                                    </ListItem>
                                                    <ListItem onClick={this.handleToTop} button style={{ justifyContent: "center" }}>
                                                        <Link to={'/Servicios'} style={{ textDecoration: 'none', color: "black" }}>Servicios</Link>
                                                    </ListItem>
                                                    <ListItem button style={{ textAlign: "center" }} onClick={this.handleContact}>
                                                        <ListItemText primary="Contacto" />
                                                    </ListItem>

                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Popover>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Contacto />
                <Menu />
            </>
        )
    }
}



function mapStateToProps(state) {
    return {
        contactPayload: state.contactReducer.contactPayload,
        getMenu: state.modalReducer.getMenu

    };
}
const header = withStyles(styles, { withTheme: true })(Header);


export default connect(mapStateToProps, {
    openContact,
    setMenu

})(header);

